import { Resolve, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ITaskDefinition } from 'src/app/shared/interfaces/taskDefinition.interface';
import { TaskService } from '../services/tasks.service';

@Injectable()
export class SystemTasksResolver implements Resolve<ITaskDefinition[]> {

  constructor(private tasksService: TaskService, private route: Router) { }

  resolve(): Observable<ITaskDefinition[]> {

    return this.tasksService.getSystemTasks()
      .pipe(
        map(systemTasks => systemTasks),
        catchError(() => [])
      );
  }
}
