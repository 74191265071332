import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { UserRoutingModule } from './workflow.routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WorkflowListComponent } from './workflow-list/workflow-list.component';
import { WorkflowListModule } from './workflow-list/workflow-list.module';
@NgModule({
  declarations: [],
  imports: [
    WorkflowListModule,
    UserRoutingModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule,
    FontAwesomeModule
  ],
  providers: [],
  entryComponents: [WorkflowListComponent],
  exports: [WorkflowListComponent]
})
export class WorkflowModule { }
