import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { IComponentPermissions, IActionList } from 'src/app/authentication/interfaces/permission.interface';
import { WorkflowService } from '../services/workflow.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
import { TaskService } from 'src/app/dashboard/components/modeler/services/tasks.service';

@Injectable()
export class WorkflowAccessGuard implements CanActivate {

  constructor(
    private router: Router,
    private alertService: AlertService,
    private authService: AuthService,
    private workflowService: WorkflowService,
    private dataSharingService: DataSharingService,
    private tasksService: TaskService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      const componentPermissions = this.getActionPermission(route.routeConfig.path);
      const permissions = await this.authService.getPermissions(componentPermissions.onLoad, componentPermissions.actions);

      this.setActionList(permissions);

      return true;
    }
    catch (error) {
      this.alertService.create({ type: 'error', body: 'Permission Denied', time: 8 });

      this.router.navigate(['dashboard'], { queryParamsHandling: 'merge' });

      return false;
    }
  }


  private getActionPermission(path: string): IComponentPermissions {

    return {
      onLoad: { resource: 'workflows', scopes: ['update:own', 'update:all'] },
      actions: {
        'templates': ['create', 'read'],
        'custom-tasks': [
          'read', 'create'
        ],
        'system-tasks': ['read'],
        'workflow-secrets': ['delete'],
        'workflow-instances': ['read', 'start'],
        'workflow-versions': ['deploy', 'delete'],
        'workflows': ['create', 'delete:all', 'delete:own', 'assign-users', 'read:all', 'read:own'],
        'workflow-triggers': [
          'webhook:create',
          'webhook:read',
          'webhook:update',
          'webhook:delete',
          'mailhook:create',
          'mailhook:read',
          'mailhook:update',
          'mailhook:delete'
        ],
      }
    };
  }


  private setActionList(permissions: string[]): void {

    const actionList: IActionList = {
      createTemplate: permissions.includes('templates#create'),
      readTemplate: permissions.includes('templates#read'),
      readCustomTasks: permissions.includes('custom-tasks#read'),
      createCustomTasks: permissions.includes('custom-tasks#create'),
      readSystemTasks: permissions.includes('system-tasks#read'),
      deleteSecret: permissions.includes('workflow-secrets#delete'),
      readInstance: permissions.includes('workflow-instances#read'),
      startInstance: permissions.includes('workflow-instances#start'),
      deployVersion: permissions.includes('workflow-versions#deploy'),
      deleteVersion: permissions.includes('workflow-versions#delete'),
      createWorkflow: permissions.includes('workflows#create'),
      readWorkflow: permissions.includes('workflows#read:all') || permissions.includes('workflows#read:own'),
      deleteWorkflow: permissions.includes('workflows#delete:all') || permissions.includes('workflows#delete:own'),
      sharingWorkflow: permissions.includes('workflows#assign-users'),
      createWebhook: permissions.includes('workflow-triggers#webhook:create'),
      readWebhook: permissions.includes('workflow-triggers#webhook:read'),
      updateWebhook: permissions.includes('workflow-triggers#webhook:update'),
      deleteWebhook: permissions.includes('workflow-triggers#webhook:delete'),
      createWailhook: permissions.includes('workflow-triggers#mailhook:create'),
      readWailhook: permissions.includes('workflow-triggers#mailhook:read'),
      updateWailhook: permissions.includes('workflow-triggers#mailhook:update'),
      deleteWailhook: permissions.includes('workflow-triggers#mailhook:delete')
    };

    this.workflowService.actionList.next(actionList);
    this.dataSharingService.actionList.next(actionList);
    this.tasksService.actionList.next(actionList);
  }
}
