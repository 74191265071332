import { Resolve, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { WorkflowService } from '../services/workflow.service';
import { map, catchError } from 'rxjs/operators';
import { IWorkflowBase } from '../interfaces/workflow.interface';

@Injectable()
export class WorkflowsResolver implements Resolve<IWorkflowBase[]> {

  constructor(private workflowService: WorkflowService, private route: Router) { }

  resolve(): Observable<IWorkflowBase[]> {

    // init number of workflows to be loaded at component initialization - the rest will be lazy loaded (infinite scrolling)
    return this.workflowService.getWorkflows(
      undefined,
      undefined,
      undefined,
      '0',
      '12'
    )
      .pipe(
        map(workflows => workflows),
        catchError(() => {
          this.route.navigate(['/error-page'], { queryParamsHandling: 'merge' });
          return of(null);
        })
      );
  }
}
