import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IUser } from '../../shared/interfaces/user.interface';
import { KeycloakService } from 'keycloak-angular';
import { IDecodedToken } from '../interfaces/decodedToken.interface';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IActionsPermissions, IOnLoadPermission, IEnforcerResponse } from '../interfaces/permission.interface';

@Injectable()
export class AuthService {
  readonly url: string;
  readonly jwtHelper: JwtHelperService;
  readonly serviceName: string;

  constructor(protected keycloakAngular: KeycloakService, private httpClient: HttpClient) {

    this.url = environment.apiUrl;
    this.jwtHelper = new JwtHelperService();
    this.serviceName = environment.keycloak.clientId;
  }

  public async getUser(): Promise<IUser> {
    const keycloakInstance = this.keycloakAngular.getKeycloakInstance();
    const parsedUserToken = <IDecodedToken> keycloakInstance.tokenParsed;

    if (parsedUserToken) {
      try {
        return this.userMapping(parsedUserToken);
      }
      catch (error) {
        await this.logout();
      }
    }
    else {
      await this.logout();
    }
  }


  private userMapping(user: IDecodedToken): IUser {

    if (
      !user ||
      !user.azp ||
      user.azp !== environment.keycloak.clientId
    ) {
      throw new Error('Invalid Token');
    }

    const userData: IUser = {
      authId: user.sub,
      username: user.preferred_username,
      name: user.name && user.name.length ? user.name : user.preferred_username,
      service: user.azp,
      picUrl: user.picUrl
    };

    return userData;
  }

  public async logout(): Promise<void> {
    await this.keycloakAngular.logout();
  }

  public async getPermissions(mainPermission: IOnLoadPermission, optionalPermissions?: IActionsPermissions): Promise<string[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let formData = new HttpParams()
      .set('grant_type', 'urn:ietf:params:oauth:grant-type:uma-ticket')
      .set('audience', 'truflow-api')
      .set('response_mode', 'permissions');

    mainPermission.scopes.forEach(scope => formData = formData.append('permission', `${mainPermission.resource}#${scope}`));

    if (optionalPermissions) {
      // tslint:disable-next-line: forin
      for (const resource in optionalPermissions) {
        optionalPermissions[resource].forEach(scope => formData = formData.append('permission', `${resource}#${scope}`));
      }
    }

    const resources = <IEnforcerResponse[]> await this.httpClient.post(
      `${environment.tokenEndpoint}`, formData, { headers }
    )
    .toPromise();

    const mainResource = resources.find(resource => resource.rsname === mainPermission.resource);
    const isAllowed = mainResource && mainResource.scopes && mainResource.scopes.filter(s => mainPermission.scopes.includes(s)).length;

    if (isAllowed) {
      const allowedActions = [];
      resources.forEach(resource => allowedActions.push(... resource.scopes.map(scope => `${resource.rsname}#${scope}`)));

      return allowedActions;
    }

    throw new Error('Permission Denied');
  }

  public updateMyProfile(): Promise<IUser> {
    return this.httpClient.post<IUser>(`${this.url}/users/me`, {}).toPromise();
  }
}
