import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TemplateService } from '../services/template.service';
import { map, catchError } from 'rxjs/operators';
import { ITemplate } from '../interfaces/template.interface';

@Injectable()
export class TemplateResolver implements Resolve<ITemplate> {

  constructor(private templateService: TemplateService, private route: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<ITemplate> {

    return this.templateService.getTemplate(route.paramMap.get('key'))
      .pipe(
        map(template => template),
        catchError(() => {

          this.route.navigate(['/error-page'], { queryParamsHandling: 'merge' });
          return of(null);
        })
      );
  }
}
