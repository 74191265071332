import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { IconDefinition, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { WorkflowService } from '../../services/workflow.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { ShortcutsModalComponent } from 'src/app/shared/components/shortcuts-modal/shortcuts-modal.component';
import { IVersion, IWorkflow, IVersionBase } from '../../interfaces';
import { Observable } from 'rxjs';
import { SettingsComponent } from './settings/settings.component';
import { RunWorkflowComponent } from '../run-workflow/run-workflow.component';
import { ModelerComponent } from 'src/app/dashboard/components/modeler/modeler.component';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
import { ITaskDefinition, ITaskDefinitionBase } from 'src/app/shared/interfaces/taskDefinition.interface';
import { SharingComponent } from './sharing/sharing.component';
import { IActionList } from 'src/app/authentication/interfaces/permission.interface';
import { CreateWorkflowComponent } from 'src/app/shared/components/create-workflow/create-workflow.component';

@Component({
  selector: 'app-access-workflow',
  templateUrl: './access-workflow.component.html',
  styleUrls: ['./access-workflow.component.scss']
})
export class AccessWorkflowComponent implements OnInit, AfterViewInit, OnDestroy {

  workflow: Partial<IWorkflow>;
  title: string;
  bsModalRef: BsModalRef;
  isWorkflowSaved: boolean;
  isWorkflowDeployed: boolean;
  faArrowLeft: IconDefinition;
  isFullscreen: boolean;
  definitions: ITaskDefinition[];
  @ViewChild('canvas', { static: false }) container: ElementRef;
  //
  // Sidebar variables
  opened = false;
  settingOpened = false;
  PropertyPanelElementValidity = {};

  @ViewChild(SettingsComponent, { static: false })
  private settingsComponent: SettingsComponent;

  @ViewChild(ModelerComponent, { static: false })
  private modelerComponent: ModelerComponent;

  @ViewChild(SharingComponent, { static: false })
  private sharingComponent: SharingComponent;

  workflowVersions$: Observable<IVersionBase[]>; // an observable array that will subscribe to listen to the workflow versions whenever called by the API
  versionData: IVersionBase[]; // the data-structure that will hold the chosen workflow version numbers
  searchWord: string; // string used as a two-way data-binding between the search bar and the component

  actionList: IActionList;

  constructor(
    private workflowService: WorkflowService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private dataSharingService: DataSharingService
  ) {
    this.isWorkflowSaved = true;
    this.isWorkflowDeployed = true;
    this.faArrowLeft = faArrowLeft;
    this.definitions = [];
    this.isFullscreen = false;
    this.actionList = {};

    this.dataSharingService.createButton.next('workflows');

    this.workflowService.actionList.subscribe(value => {
      this.actionList = value;
    });
  }

  ngOnInit(): void {
    this.workflow = this.activatedRoute.snapshot.data.workflow;
    this.title = this.workflow.name;

    document.addEventListener('fullscreenchange', () =>
      this.isFullscreen = document.fullscreenElement ? true : false
    );
  }

  ngOnDestroy(): void {
    if (document.fullscreenElement) document.exitFullscreen();
  }

  ngAfterViewInit(): void {

    this.workflowService.showShareWith.subscribe(isOpened  => {
      if (isOpened) {
        this.sharingComponent.sharingOpened = isOpened;
        this.workflowService.showShareWith.next(false);
      }
    });

    this.modelerComponent.listenOnEvent('commandStack.shape.delete.execute', (e) => {
      delete this.PropertyPanelElementValidity[e.context.shape.id];
    });

    this.modelerComponent.listenOnEvent('commandStack.changed', () => {
      this.isWorkflowSaved = false;
    });

    this.modelerComponent.listenOnEvent('validPanel', (data) => {
      this.PropertyPanelElementValidity[data.elementId] = this.PropertyPanelElementValidity[data.elementId] || {};
      this.PropertyPanelElementValidity[data.elementId] = {
        name: data.elementName,
        valid: data.validPanel
      }
    });

  }

  // SIDE-BAR functions //

  toggleSideBar() {
    const key = this.workflow.key;

    // setting the 'opened' true/false each time the user clicks on it, to be able to toggle the slider bar open/close
    this.opened = !this.opened;

    // get the workflow's versions from the DB - the endpoint returns an observable,then we store the versions in the versionData
    this.workflowVersions$ = this.workflowService.getWorkflowVersions(key, 0, 100, true);
    this.workflowVersions$.subscribe((res) => {
      this.versionData = res;
    });
  }

  public toggleSettingBar() {
    this.settingsComponent.settingOpened = !this.settingsComponent.settingOpened;
  }

  public toggleSharingBar() {
    this.sharingComponent.sharingOpened = !this.sharingComponent.sharingOpened;
  }

  public showSettings(): void {
    this.settingsComponent.loadSecretData();
  }

  openWorkflow(key: string) {
    this.router.navigate([`/workflow-overview`, key], { queryParamsHandling: 'merge' });
  }

  public async saveDiagram(): Promise<void> {

    const invalidTasks: string[] = [];

    for (const taskId in this.PropertyPanelElementValidity) {
      if (this.PropertyPanelElementValidity[taskId].valid === false) {
        const taskName = this.PropertyPanelElementValidity[taskId].name;
        invalidTasks.push(`${taskId} ${taskName ? `(${taskName})` : ''}  `);
      }
    }

    if (invalidTasks.length) {
      this.alertService.create({ type: 'error', body: `Invalid input data for tasks: ${invalidTasks.join(' , ')}`, time: 8 });
    }
    else {
      const xml = await this.modelerComponent.getXml();

      this.workflowService.updateWorkflowXml(this.workflow.key, xml).toPromise()
        .then((updatedWorkflow: IWorkflow) => {

          Object.assign(this.workflow, { name: updatedWorkflow.name, xml });
          this.title = updatedWorkflow.name;
          this.isWorkflowSaved = true;
          this.isWorkflowDeployed = false;

          this.alertService.create({ type: 'success', body: 'the workflow is updated successfully.', time: 3 });
        });
    }
  }

  public deployDiagram(): void {

    this.workflowService.deployWorkflow(this.workflow.key).toPromise()
      .then(() => {
        this.alertService.create({ type: 'success', body: 'the workflow is deployed successfully.', time: 3 });
        this.isWorkflowDeployed = true;
      });
  }

  public openHistoryLogs(): void {
    this.router.navigate([`/workflow-overview`, this.workflow.key], { queryParamsHandling: 'merge' });
  }

  public deleteWorkflow(): void {
    const modalOpts: ModalOptions = {
      initialState: {
        body: `Are you sure you want to permanently delete workflow ${this.workflow.name}`,
        initiator: 'app-workflow-list'
      },
      backdrop: 'static',
      ignoreBackdropClick: false,
      animated: true,
      keyboard: false
    };

    this.bsModalRef = this.modalService.show(ConfirmModalComponent, modalOpts);

    this.bsModalRef.content.onClose.subscribe(result => {

      if (result.initiator === 'app-workflow-list' && result.confirm) {

        this.workflowService.deleteWorkflow(this.workflow.key).toPromise()
          .then(() => {

            this.alertService.create({ type: 'success', body: 'the workflow is deleted successfully.', time: 3 });
            this.router.navigate(['', { queryParamsHandling: 'merge' }]);
          });
      }
    });
  }

  public openShortcuts(): void {
    const modalOpts: ModalOptions = {
      ignoreBackdropClick: false,
      animated: true,
    };

    this.bsModalRef = this.modalService.show(ShortcutsModalComponent, modalOpts);
  }

  public startLatestVersion(): void {
    const initialState = {
      workFlowKey: this.workflow.key,
      inputSchema: this.workflow.inputSchema
    };
    this.bsModalRef = this.modalService.show(RunWorkflowComponent, { initialState, class: 'gray modal-lg' });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  public toggleFullScreen(): void {
    !document.fullscreenElement ? document.documentElement.requestFullscreen() : document.exitFullscreen();
  }

  public cloneWorkflow() {
    const initialState = { title: `Clone Workflow From workflow ${this.workflow.name}`, type: 'workflow', xml: this.workflow.xml };
    this.bsModalRef = this.modalService.show(CreateWorkflowComponent, { initialState, class: 'gray modal-lg' });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  public createTemplateFromWorkflow() {
    const initialState = { title: `Create Template From workflow ${this.workflow.name}`, type: 'template', xml: this.workflow.xml };
    this.bsModalRef = this.modalService.show(CreateWorkflowComponent, { initialState, class: 'gray modal-lg' });
    this.bsModalRef.content.closeBtnName = 'Close';
  }
}
