import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { AlertService } from 'src/app/shared/services/alert.service';
import { IWorkflow, ITrigger, IVersionBase } from '../../../interfaces';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { WorkflowService } from '../../../services/workflow.service';
import { IconDefinition, faClone, faEdit } from '@fortawesome/free-solid-svg-icons';
import { TriggerType } from '../../../enums/trigger.enum';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { IUser } from 'src/app/shared/interfaces/user.interface';
import { IActionList } from 'src/app/authentication/interfaces/permission.interface';

@Component({
  selector: 'app-workflow-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, AfterViewInit {
  @Input() workflow: IWorkflow;
  settingOpened: boolean;
  showTriggers: boolean;
  showSecrets: boolean;
  showSharing: boolean;
  showAddSecrets: boolean;
  showAddTriggers: boolean;
  showAddInputSchema: boolean;
  submitted: boolean;
  secretKeys: string[];
  triggers: ITrigger[];
  triggerToUpdate?: ITrigger;
  triggerType: string;
  keyValueForm: FormGroup;
  triggerForm: FormGroup;
  inputSchemaForm: FormGroup;
  faClone: IconDefinition;
  faEdit: IconDefinition;
  bsModalRef: BsModalRef;
  versions: IVersionBase[];
  users: IUser[];
  actionList: IActionList;

  constructor(
    private workflowService: WorkflowService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService
  ) {
    this.settingOpened = false;
    this.showTriggers = false;
    this.showSecrets = true;
    this.showSharing = false;
    this.showAddSecrets = false;
    this.showAddTriggers = false;
    this.showAddInputSchema = false;
    this.submitted = false;
    this.secretKeys = [];
    this.triggerType = TriggerType.webhook;
    this.faClone = faClone;
    this.faEdit = faEdit;
    this.versions = [];
    this.users = [];
    this.actionList = {};

    this.workflowService.actionList.subscribe(value => {
      this.actionList = value;
    });
  }

  ngAfterViewInit(): void {
    this.workflowService.userSharedWith.subscribe(sharedUser  => {
      if(sharedUser)  this.users.push(sharedUser);
    });
  }

  ngOnInit(): void {
    this.keyValueForm = this.formBuilder.group({
      key: ['', Validators.required],
      value: ['', Validators.required]
    });

    this.triggerForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: [''],
      version: []
    });

    this.inputSchemaForm = this.formBuilder.group({
      schemaValue: [null, this.jsonValidator]
    });
  }

  jsonValidator(control: FormControl) {
    if (control.value) {
      try {
        JSON.parse(control.value);
      }
      catch {
        return { pattern: 'Invalid Json' };
      }
    }

    return null;
  }

  public close(): void {
    this.settingOpened = false;
    this.showSecrets = true;
    this.showSharing = false;
    this.showAddInputSchema = false;
    this.showTriggers = false;
    this.showAddSecrets = false;
  }

  loadSecretData() {
    this.showSecrets = true;
    this.showTriggers = false;
    this.showAddTriggers = false;
    this.showSharing = false;
    this.showAddInputSchema = false;
    this.triggerToUpdate = undefined;
    this.workflowService.getSecretData(this.workflow.key).toPromise()
    .then((secretData) => {
      this.secretKeys = secretData;
    });
  }

  addSecretData(formValues) {
    this.submitted = true;

    if (!this.keyValueForm.invalid) {

      if (this.secretKeys.includes(formValues.key)) {
        this.alertService.create({ type: 'error', body: `Secret Key "${formValues.key}" already exits`, time: 4 });
      }
      else {
        this.workflowService.addSecretKeyValue(this.workflow.key, formValues.key, formValues.value).toPromise()
          .then(() => {
            this.alertService.create({ type: 'success', body: 'Secret is Added successfully', time: 3 });
            this.secretKeys.push(formValues.key);
            this.keyValueForm.reset();
            this.showAddSecrets = false;
            this.submitted = false;
          });
      }
    }
  }

  cancelSecret() {
    this.keyValueForm.reset();
    this.submitted = false;
    this.showAddSecrets = false;
  }

  deleteKeySecret(key: string): void {
    const modalOpts: ModalOptions = {
      initialState: {
        body: `Are you sure you want to permanently delete secret ${key}`,
        initiator: 'app-workflow-settings'
      },
      backdrop: 'static',
      ignoreBackdropClick: false,
      animated: true,
      keyboard: false
    };

    this.bsModalRef = this.modalService.show(ConfirmModalComponent, modalOpts);

    this.bsModalRef.content.onClose.subscribe(result => {

      if (result.initiator === 'app-workflow-settings' && result.confirm) {

        this.workflowService.deleteSecretKeyValue(this.workflow.key, key).toPromise()
        .then(() => {
          this.alertService.create({ type: 'success', body: 'Secret is Deleted successfully', time: 3 });
          const deletedIndex = this.secretKeys.findIndex(ele => ele === key);
          this.secretKeys.splice(deletedIndex, 1);
        });
      }
    });
  }

  toggleNewSecrets() {
    this.showAddSecrets = !this.showAddSecrets;
  }

  loadTriggers(triggerType: string) {
    this.triggers = [];
    this.showTriggers = true;
    this.showSecrets = false;
    this.showSharing = false;
    this.showAddInputSchema = false;
    this.showAddTriggers = false;
    this.triggerToUpdate = undefined;
    this.showAddSecrets = false;
    this.triggerType = triggerType;

    this.workflowService.getTriggers(this.workflow.key, triggerType).toPromise()
      .then((triggers) => {
        const valuePath = this.triggerType === TriggerType.webhook ? 'url' : 'email';
        triggers.forEach(trigger => trigger.value = trigger[valuePath]);
        this.triggers = triggers;

        this.workflowService.getWorkflowVersions(this.workflow.key).subscribe((res) => {
          this.versions = res;
        });
      });
  }

  addTrigger(formValues) {
    this.submitted = true;

    this.workflowService.addTrigger(
      this.workflow.key, this.triggerType, formValues.name,
      formValues.description, formValues.version
    ).toPromise()
      .then((trigger) => {
        const valuePath = this.triggerType === TriggerType.webhook ? 'url' : 'email';
        trigger.value = trigger[valuePath];

        this.triggers.push(trigger);
        this.triggerForm.reset();
        this.showAddTriggers = false;
        this.submitted = false;
      });
  }

  cancelTrigger() {
    this.triggerForm.reset();
    this.submitted = false;
    this.showAddTriggers = false;
    this.triggerToUpdate = undefined;
  }

  copyTriggerValue(value: string) {
    const selBox = document.createElement('textarea');
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  deleteTrigger(triggerKey: string): void {
    const modalOpts: ModalOptions = {
      initialState: {
        body: `Are you sure you want to permanently delete ${this.triggerType} ${triggerKey}`,
        initiator: 'app-workflow-settings'
      },
      backdrop: 'static',
      ignoreBackdropClick: false,
      animated: true,
      keyboard: false
    };

    this.bsModalRef = this.modalService.show(ConfirmModalComponent, modalOpts);

    this.bsModalRef.content.onClose.subscribe(result => {

      if (result.initiator === 'app-workflow-settings' && result.confirm) {

        this.workflowService.deleteTrigger(this.workflow.key, this.triggerType, triggerKey).toPromise()
        .then(() => {
          this.alertService.create({ type: 'success', body: `${this.triggerType} is Deleted successfully`, time: 3 });

          const deletedIndex = this.triggers.findIndex(ele => ele.triggerKey === triggerKey);
          this.triggers.splice(deletedIndex, 1);
        });
      }
    });
  }

  revokeTrigger(triggerKey: string): void {
    const foundTrigger = this.triggers.find(ele => ele.triggerKey === triggerKey);
    const revoked = !foundTrigger.revoked;

    this.workflowService.updateTrigger(this.workflow.key, this.triggerType, triggerKey, revoked).toPromise()
      .then(() => {
        this.alertService.create({
          type: 'success',
          body: `${this.triggerType} is ${revoked ? 'Revoked' : 'Activated'} successfully`,
          time: 3
        });

        foundTrigger.revoked = revoked;
      });
  }

  updateTrigger(formValues): void {
    this.submitted = true;
    const updatedVersion = formValues.version ? this.versions.find(v => v.version === formValues.version) : { version: null };
    const updatedDescription = formValues.description && formValues.description.length ? formValues.description : null;

    this.workflowService.updateTrigger(
      this.workflow.key, this.triggerType, this.triggerToUpdate.triggerKey, undefined,
      formValues.name, updatedDescription, updatedVersion
    ).toPromise()
      .then(() => {
        const updatedTrigger = this.triggers.find(t => t.triggerKey === this.triggerToUpdate.triggerKey);
        Object.assign(updatedTrigger, formValues, { workflowVersion: formValues.version });

        this.triggerForm.reset();
        this.showAddTriggers = false;
        this.triggerToUpdate = undefined;
        this.submitted = false;
      });
  }

  toggleNewTrigger() {
    this.showAddTriggers = !this.showAddTriggers;
  }

  toggleUpdateTrigger(trigger: ITrigger) {
    this.triggerForm.controls.name.setValue(trigger.name);
    this.triggerForm.controls.description.setValue(trigger.description);
    this.triggerForm.controls.version.setValue(trigger.workflowVersion);
    this.triggerToUpdate = trigger;
  }

  loadSharedUsers() {
    this.showSharing = true;
    this.showTriggers = false;
    this.showSecrets = false;
    this.showAddTriggers = false;
    this.showAddSecrets = false;
    this.showAddInputSchema = false;

    this.workflowService.getWorkflowAssignedUsers(this.workflow.key).toPromise()
    .then((users) => {
      this.users = users;
    });
  }

  openSharingSidebar() {
    this.workflowService.showShareWith.next(true);
  }

  unassignWorkflowUser(authId: string) {
    this.workflowService.unassignWorkflowUser(this.workflow.key, authId).toPromise()
    .then(() => {
      this.alertService.create({ type: 'success', body: `User is Unassigned successfully`, time: 3 });
      const deletedIndex = this.users.findIndex(ele => ele.authId === authId);
      this.users.splice(deletedIndex, 1);
    });
  }

  loadInputSchemaForm() {
    this.showAddInputSchema = true;
    this.showSharing = false;
    this.showTriggers = false;
    this.showSecrets = false;
    this.showAddTriggers = false;
    this.showAddSecrets = false;

    this.inputSchemaForm.setValue({
      schemaValue: this.workflow.inputSchema ? JSON.stringify(this.workflow.inputSchema, null, 2) : null
    });
  }

  addInputSchema(formValues) {
    this.submitted = true;

    if (!this.inputSchemaForm.invalid) {

      this.workflowService.updateWorkflowInputSchema(this.workflow.key, formValues.schemaValue).toPromise()
        .then((inputSchema) => {

          this.workflow.inputSchema = inputSchema;
          this.submitted = false;

          this.alertService.create({ type: 'success', body: 'the workflow is updated successfully.', time: 3 });
        });
    }
  }
}
