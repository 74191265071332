import { KeycloakConfig } from 'keycloak-angular';

const keycloakConfig: KeycloakConfig = {
  realm: 'trufla',
  url: 'https://secure.trufla.dev/auth',
  clientId: 'truflow-angular'
};

export const environment = {
  production: false,
  keycloak: keycloakConfig,
  apiUrl: 'https://truflow-api.trufla.dev',
  baseDomain: 'truflow.dev',
  tokenEndpoint: '',
  apiMock: false
};
