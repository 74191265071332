import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { ITemplate, ITemplateBase } from '../interfaces/template.interface';
import { IActionList } from 'src/app/authentication/interfaces/permission.interface';

@Injectable()
export class TemplateService {

  readonly url: string = environment.apiUrl;
  public actionList: BehaviorSubject<IActionList>;

  constructor(private httpClient: HttpClient) {
    this.actionList = new BehaviorSubject<IActionList>({});
  }

  public getTemplates(
    sortBy: string = 'updatedAt',
    nameLike?: string,
    offset?: string,
    limit?: string
  ): Observable<ITemplateBase[]> {
    let params = (new HttpParams()).set('offset', offset).set('limit', limit);

    if (nameLike) params = params.set('nameLike', nameLike);

    if (sortBy) {
      params = sortBy === 'name' ?
        params.set('sortBy', 'name').set('sortOrder', 'asc') : params.set('sortBy', sortBy).set('sortOrder', 'desc');
    }

    return <Observable<ITemplateBase[]>> this.httpClient.get(`${this.url}/templates`,
        { params }
      )
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public deleteTemplate(key: string): Observable<ITemplate> {

    return <Observable<ITemplate>>this.httpClient.delete(`${this.url}/templates/${key}`)
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public updateTemplate(key: string, xml: string): Observable<ITemplate> {

    return <Observable<ITemplate>>this.httpClient.put<ITemplate>(`${this.url}/templates/${key}`, { xml })
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public addTemplate(xml: string): Observable<ITemplate> {

    return <Observable<ITemplate>> this.httpClient.post(`${this.url}/templates`, { xml })
      .pipe(
        map(response => response),
        catchError(err => throwError(err)));
  }

  public getTemplate(key: string): Observable<ITemplate> {
    return <Observable<ITemplate>> this.httpClient.get(`${this.url}/templates/${key}`)
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }
}
