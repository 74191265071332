import { Component, OnInit } from '@angular/core';
import { AuthService } from './authentication/services/auth.service';
import { IUser } from './shared/interfaces/user.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  constructor(private authService: AuthService) { }

  user: IUser;

  async ngOnInit(): Promise<void> {
    await this.authService.updateMyProfile();

    this.user = await this.authService.getUser();
  }

}
