import { Pipe, PipeTransform } from '@angular/core';
import { IWorkflow } from '../interfaces/workflow.interface';

@Pipe({
  name: 'workflowFilterPipe',
  pure: false
})
export class WorkflowFilterPipe implements PipeTransform {

  transform(workflows: IWorkflow[], nameFilter: string): IWorkflow[] {

    if (!workflows || !nameFilter) {
      return workflows;
    }

    return workflows.filter(workflow => workflow.name.toLowerCase().includes(nameFilter.toLowerCase()));
  }
}
