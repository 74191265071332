import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { IComponentPermissions, IActionList } from 'src/app/authentication/interfaces/permission.interface';
import { WorkflowService } from '../services/workflow.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';

@Injectable()
export class WorkflowListGuard implements CanActivate {

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private workflowService: WorkflowService,
    private dataSharingService: DataSharingService
  ) { }

  async canActivate(): Promise<boolean> {
    try {
      const componentPermissions = this.getActionPermission();
      const permissions = await this.authService.getPermissions(componentPermissions.onLoad, componentPermissions.actions);

      this.setActionList(permissions);

      return true;
    }
    catch (error) {
      this.alertService.create({ type: 'error', body: 'Permission Denied', time: 8 });

      await this.authService.logout();

      return false;
    }
  }


  private getActionPermission(): IComponentPermissions {

    return {
      onLoad: { resource: 'workflows', scopes: ['read:own', 'read:all'] },
      actions: {
        'users': ['read'],
        'templates': ['create', 'read'],
        'workflow-instances': ['read', 'start'],
        'workflow-versions': ['deploy', 'delete'],
        'workflows': [
          'create',
          'update:all',
          'update:own',
          'delete:all',
          'delete:own'
        ]
      }
    };
  }


  private setActionList(permissions: string[]): void {

    const actionList: IActionList = {
      readUser: permissions.includes('users#read'),
      createTemplate: permissions.includes('templates#create'),
      readTemplate: permissions.includes('templates#read'),
      readInstance: permissions.includes('workflow-instances#read'),
      startInstance: permissions.includes('workflow-instances#start'),
      deployVersion: permissions.includes('workflow-versions#deploy'),
      deleteVersion: permissions.includes('workflow-versions#delete'),
      createWorkflow: permissions.includes('workflows#create'),
      readWorkflow: permissions.includes('workflows#read:all') || permissions.includes('workflows#read:own'),
      deleteWorkflow: permissions.includes('workflows#delete:all') || permissions.includes('workflows#delete:own'),
      updateWorkflow: permissions.includes('workflows#update:all') || permissions.includes('workflows#update:own')
    };

    this.workflowService.actionList.next(actionList);
    this.dataSharingService.actionList.next(actionList);
  }
}
