import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '../../authentication/services/auth.service';
import { environment } from '../../../environments/environment';
import { AlertService } from '../services/alert.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private alertService: AlertService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        catchError((error) => {

          if (request.url.includes(environment.apiUrl)) {
            switch (error.status) {
              case 400:

                this.alertService.create({
                  body: error.error.error ? `${error.error.error}` : 'Invalid Request Data',
                  time: 10, type: 'error'
                });
                break;
              case 401:
                this.alertService.create({
                  body: error.error.error ? `${error.error.error}` : 'Unauthorized',
                  time: 10, type: 'error'
                });
                this.authService.logout();
                break;

              case 403:
                this.alertService.create({
                  body: error.error.error ? `${error.error.error}` : 'Permission Denied',
                  time: 10, type: 'error'
                });
                break;

              case 404:
                this.alertService.create({
                  body: error.error.error ? `${error.error.error}` : 'Not Found Resource',
                  time: 10, type: 'error'
                });
                break;

              default:
                this.alertService.create({
                  body: 'Server error, something went wrong if this error persists please call support.',
                  time: 10, type: 'error'
                });
                break;
            }
          }
          else {
            this.alertService.create({
              body: 'Server error, something went wrong if this error persists please call support.',
              time: 10, type: 'error'
            });
          }

          return throwError(error);
        })
      );
  }
}
