import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { IconDefinition, faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { IUser } from '../../interfaces/user.interface';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle'; // for better performance, better to include just the desired font versus the whole library of fonts
import { DataSharingService } from '../../services/data-sharing.service';
import { IActionList } from 'src/app/authentication/interfaces/permission.interface';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CreateWorkflowComponent } from '../create-workflow/create-workflow.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  @Input() user: IUser;
  public faCog: IconDefinition;
  public faSignOutAlt: IconDefinition;
  public faUser: IconDefinition;
  public isTemplate: boolean;
  public actionList: IActionList;
  isFullscreen: boolean;
  bsModalRef: BsModalRef;

  constructor(private authService: AuthService, private dataSharingService: DataSharingService, private modalService: BsModalService) {
    this.actionList = {};
    this.isFullscreen = false;
    this.dataSharingService.createButton.subscribe(value => {

      if (value === 'templates') {
        this.isTemplate = true;
      }
      else {
        this.isTemplate = false;
      }
    });

    this.dataSharingService.actionList.subscribe(value => {
      this.actionList = value;
    });
  }

  ngOnInit(): void {

    this.faCog = faCog;
    this.faSignOutAlt = faSignOutAlt;
    this.faUser = faUserCircle;
    this.isTemplate = false;

    document.addEventListener('fullscreenchange', () =>
      this.isFullscreen = document.fullscreenElement ? true : false
    );
  }

  createWorkflow(type) {
    const initialState = { type };
    this.bsModalRef = this.modalService.show(CreateWorkflowComponent, { initialState, class: 'gray modal-lg' });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  async logout(): Promise<void> {
    await this.authService.logout();
  }
}
