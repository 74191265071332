import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyWorkComponent } from './my-work.component';
import { MyWorkAccessGuard } from './guards/my-work-access.guard';

const routes: Routes = [
  {
    path: 'my-work',
    component: MyWorkComponent,
    canActivate: [MyWorkAccessGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyWorkRoutingModule { }
