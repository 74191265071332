import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { ICustomTaskBase } from '../custom-task/custom-task.interface';
import { IActionList } from 'src/app/authentication/interfaces/permission.interface';
import { AuthService } from 'src/app/authentication/services/auth.service';

@Component({
  selector: 'app-task-card',
  templateUrl: './task-card.component.html',
  styleUrls: ['./task-card.component.scss']
})
export class TaskCardComponent implements OnInit {
  @Input() task: ICustomTaskBase;
  @Input() actionList: IActionList;
  @Output() deleteTask = new EventEmitter<ICustomTaskBase>();
  @Output() editTask = new EventEmitter<ICustomTaskBase>();

  ngOnInit(): void {}

  deleteCurrentTask(): void {
    this.deleteTask.emit(this.task);
  }

  editCurrentTask() {
    this.editTask.emit(this.task);
  }
}
