import { Component } from '@angular/core';
import { IconDefinition, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { TaskService } from 'src/app/dashboard/components/modeler/services/tasks.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IActionList } from 'src/app/authentication/interfaces/permission.interface';

@Component({
  selector: 'app-my-work',
  templateUrl: './my-work.component.html',
  styleUrls: ['./my-work.component.scss']
})
export class MyWorkComponent  {
  faArrowLeft: IconDefinition;
  bsModalRef: BsModalRef;
  actionList: IActionList;
  showCustomTasks: boolean;

  constructor(private tasksService: TaskService) {
    this.faArrowLeft = faArrowLeft;
    this.actionList = {};
    this.showCustomTasks = true;

    this.tasksService.actionList.subscribe(value => {
      this.actionList = value;
    });
  }

  openCustomTasks() {
    this.showCustomTasks = true;
  }
}
