import { Pipe, PipeTransform } from '@angular/core';
import { IVersion } from './interfaces/version.interface';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {
  /**
   * @param versions: array of workflow versions passed by default through the searFilter Pipe from the workflow-list html
   * @param searchTerm: search keyword passed as an argument parameter through the searchFilter Pipe from the workflow-list html
   * entered initially by the user in the search field in the rendered html
   * return: if the versions' array is empty or there's no search word 'searchTerm' then the original versions' array is returned
   * otherwise we filter the array to check if it contains the searchTerm, if the array item isn't similar to the version in the
   * original array then a -1 indicates the item doesn't exist,
   * otherwise this filter returns an array of workflow versions searched by the user
   */
  transform(versions: IVersion[], searchTerm: string): IVersion[] {
    if (!versions || !searchTerm){
      return versions;
    }
    return versions.filter(
      version => version.version.toString().indexOf(searchTerm) !== -1
    );
  }
}
