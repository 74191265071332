import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NewCustomTaskComponent } from '../../../components/modeler/add-custom-task/add-custom-task.component';
import { TaskService } from 'src/app/dashboard/components/modeler/services/tasks.service';
import { ICustomTaskBase, INewCustomTask, ICustomTaskUpdates } from './custom-task.interface';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ModalOptions, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { IActionList } from 'src/app/authentication/interfaces/permission.interface';

@Component({
  selector: 'app-custom-task',
  templateUrl: './custom-task.component.html',
  styleUrls: ['./custom-task.component.scss']
})
export class CustomTaskComponent implements OnInit, OnDestroy {

  @ViewChild(NewCustomTaskComponent, { static: false })
  private customTaskComponent: NewCustomTaskComponent;

  customTasks: ICustomTaskBase[];
  bsModalRef: BsModalRef;
  actionList: IActionList;
  taskActionList: IActionList;

  constructor(
    private taskService: TaskService,
    private alertService: AlertService,
    private modalService: BsModalService
  ) {
    this.customTasks = [];
    this.actionList = {};
    this.taskActionList = {};

    this.taskService.actionList.subscribe(value => {
      this.actionList = value;
      this.taskActionList = {
        updateTasks: this.actionList.updateCustomTasks,
        deleteTasks: this.actionList.deleteCustomTasks,
        cloneTasks: this.actionList.createSharedTasks
      };
    });
  }

  async ngOnInit() {
    this.customTasks = await this.taskService.getCustomTasks(undefined, true).toPromise();
  }

  ngOnDestroy() {
    this.customTasks = [];
  }

  addCustomTask() {
    this.customTaskComponent.isTaskFormOpened = true;
  }

  deleteCustomTask(customTask: ICustomTaskBase): void {
    const modalOpts: ModalOptions = {
      initialState: {
        body: `Are you sure you want to permanently delete ${customTask.name}`,
        initiator: 'app-custom-task'
      },
      backdrop: 'static',
      ignoreBackdropClick: false,
      animated: true,
      keyboard: false
    };

    this.bsModalRef = this.modalService.show(ConfirmModalComponent, modalOpts);

    this.bsModalRef.content.onClose.subscribe(result => {

      if (result.initiator === 'app-custom-task' && result.confirm) {

        this.taskService.deleteCustomTask(customTask.taskId)
          .toPromise()
          .then(() => {
            this.alertService.create({ type: 'success', body: `${customTask.name} is Deleted successfully`, time: 3 });

            const deletedIndex = this.customTasks.findIndex(ele => ele.taskId === customTask.taskId);
            this.customTasks.splice(deletedIndex, 1);
          });
      }
    });
  }

  editCustomTask(customTask: ICustomTaskBase) {
    this.taskService.getCustomTaskById(customTask.taskId)
      .toPromise()
      .then((targetCustomTask) => {
        this.customTaskComponent.openTaskUpdateForm(targetCustomTask);
      });
  }

  createCustomTask(customTask: INewCustomTask) {
    let createdTask;

    this.taskService.createCustomTask(
      customTask.name, customTask.properties, customTask.fileName, customTask.fileSize,
      customTask.language, customTask.hasOutput,  customTask.description
    )
    .toPromise()
    .then((response) => {
      createdTask = response;
      return this.taskService.uploadCodeFile(createdTask.uploadUrl, customTask.file).toPromise();
    })
    .then(() => {
      this.alertService.create({ type: 'success', body: 'Custom Task is Added successfully', time: 4 });
      this.customTasks.push({ taskId: createdTask.taskId, createdAt: new Date(), ...customTask });
      this.customTaskComponent.reset();
    });
  }

  updateCustomTask(customTaskUpdates: ICustomTaskUpdates) {
    const fileInfo: any = customTaskUpdates.file ? { name: customTaskUpdates.fileName, size: customTaskUpdates.fileSize } : {};

    this.taskService.updateCustomTask(
      customTaskUpdates.taskId, customTaskUpdates.name,
      customTaskUpdates.properties,
      fileInfo.name, fileInfo.size, customTaskUpdates.language,
      customTaskUpdates.hasOutput, customTaskUpdates.description
    )
    .toPromise()
    .then((updatedTask) => {

      if (updatedTask.uploadUrl) {
        return this.taskService.uploadCodeFile(updatedTask.uploadUrl, customTaskUpdates.file).toPromise();
      }
    })
    .then(() => {
      this.alertService.create({ type: 'success', body: 'Custom Task is Updated successfully', time: 4 });

      const taskToUpdate = this.customTasks.find(task => task.taskId === customTaskUpdates.taskId);
      if (taskToUpdate) Object.assign(taskToUpdate, customTaskUpdates);

      this.customTaskComponent.reset();
    });
  }

  downloadCode(taskId: string) {
    this.taskService.getCustomTaskCodeFile(taskId);
  }
}
