import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IActionList } from 'src/app/authentication/interfaces/permission.interface';

@Injectable()
export class DataSharingService {
    public createButton: BehaviorSubject<string>;
    public actionList: BehaviorSubject<IActionList>;

    constructor() {
        this.createButton = new BehaviorSubject<string>('workflow');
        this.actionList = new BehaviorSubject<IActionList>({});
    }
}
