
export default class CustomPalette {

  constructor(bpmnFactory, create, elementFactory, palette, eventBus) {

    let searchWord;

    eventBus.on('diagram.init', () => {
      
      eventBus.createEvent('search-task');
      eventBus.createEvent('request-task-definition');

      const currentPaletteEntries = palette._entries;
      const palleteContainer = palette._container;
      palleteContainer.insertAdjacentHTML('afterbegin', CustomPalette.BOX_HTML);


      eventBus.on('system-tasks', (systemTasks) => {

        addSystemTasks(
          bpmnFactory, create, elementFactory, eventBus, currentPaletteEntries, systemTasks
        );

        redrawPaletteAfterSearch(palette, currentPaletteEntries);
      });

      eventBus.on('custom-tasks', (data) => {

        const filteredPalleteEntries = filterPalleteEntries(currentPaletteEntries, searchWord);
        const filteredCustomTasks = filterCustomTasks(
          bpmnFactory, create, elementFactory, eventBus, currentPaletteEntries, searchWord, data
        );

        redrawPaletteAfterSearch(palette, Object.assign({}, filteredPalleteEntries, filteredCustomTasks));
      });

      palleteContainer.firstChild.addEventListener('input', (event) => {
        searchWord = event.target.value.toLowerCase();

        if (searchWord) {
          eventBus.fire('search-task', { nameLike: searchWord });
        }
        else {
          redrawPaletteAfterSearch(palette, currentPaletteEntries);
        }
      });
    });
  }
}

function filterCustomTasks(bpmnFactory, create, elementFactory, eventBus, currentPaletteEntries, searchWord, searchResultData) {

  const matchedTasks = {};

  if (searchResultData.definitions.length && searchWord === searchResultData.searchWord) {

    if (!currentPaletteEntries['custom-separator']) {
      matchedTasks['custom-separator'] = currentPaletteEntries['custom-separator'] = {
        group: 'custom',
        separator: true
      };
    }

    searchResultData.definitions.forEach(definition => {
      var taskName = definition.taskId;

      matchedTasks[taskName] = currentPaletteEntries[taskName] = {
        group: 'custom',
        className: 'bpmn-icon-task',
        title: definition.name,
        action: {
          dragstart: createTask('custom-tasks', definition.taskId, bpmnFactory, create, elementFactory, eventBus),
          click: createTask('custom-tasks', definition.taskId, bpmnFactory, create, elementFactory, eventBus)
        }
      }
    });
  }

  return matchedTasks;
}

function addSystemTasks(bpmnFactory, create, elementFactory, eventBus, currentPaletteEntries, systemTasks) {

  currentPaletteEntries['system-tasks-sep'] = {
    group: 'system-tasks',
    separator: true
  };

  systemTasks.definitions.forEach(definition => {
    var taskName = definition.taskId;

    currentPaletteEntries[taskName] = {
      className: definition.icon || 'bpmn-icon-task',
      title: definition.name,
      action: {
        dragstart: createTask('system-tasks', definition.taskId, bpmnFactory, create, elementFactory, eventBus),
        click: createTask('system-tasks', definition.taskId, bpmnFactory, create, elementFactory, eventBus)
      }
    }
  });
}

function filterPalleteEntries(currentPaletteEntries, searchWord) {

  const matchedEntries = {};

  Object.entries(currentPaletteEntries).forEach(([key, value]) => {
    if (key === 'custom-separator' || value.title && value.title.toLowerCase().includes(searchWord)) {
      matchedEntries[key] = value;
    }
  });
  return matchedEntries;
}

function createTask(topic, taskId, bpmnFactory, create, elementFactory, eventBus) {

  return function (event) {
    const businessObject = bpmnFactory.create('bpmn:ServiceTask');
    businessObject.topic = topic;
    businessObject.taskId = taskId;
    businessObject.type = 'external'

    const shape = elementFactory.createShape({
      type: 'bpmn:ServiceTask',
      businessObject: businessObject
    });

    create.start(event, shape);
  }
};

function redrawPaletteAfterSearch(palette, matchedEntries) {

  const paletteProvider = {
    getPaletteEntries: () => entries => matchedEntries
  };

  palette.registerProvider(paletteProvider);
}

CustomPalette.$inject = [
  'bpmnFactory',
  'create',
  'elementFactory',
  'palette',
  'eventBus'
];

CustomPalette.BOX_HTML =
  `<input type='search' id='search-palatte' style=' 
        background-image: url(assets/images/Search.svg); 
        background-position: 4px;
        background-repeat:no-repeat;
        padding-left: 23px;
        margin-bottom: 40px;
        height: 29px;
        width: 137px;
        border: 1px solid #D8D8D8;
        border-radius: 6px;
        position: relative;
        top: 10px;
        left: 10px;
        outline-offset: 0;
      '/>
    `;




