import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MyWorkComponent } from './my-work.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MyWorkAccessGuard } from './guards/my-work-access.guard';
import { MyWorkRoutingModule } from './my-work.routing.module';
import { ModelerModule } from '../components/modeler/modeler.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CustomTaskComponent } from './components/custom-task/custom-task.component';
import { TaskCardComponent } from './components/task-card/task-card.component';

@NgModule({
  declarations: [
    MyWorkComponent,
    TaskCardComponent,
    CustomTaskComponent
  ],
  imports: [
    FontAwesomeModule,
    ModelerModule,
    MyWorkRoutingModule,
    CommonModule,
    RouterModule,
    BsDropdownModule,
    NgbModule,
    ModalModule
  ],
  providers: [
    MyWorkAccessGuard
  ],
  entryComponents: [MyWorkComponent],
  exports: []
})
export class MyWorkModule { }
