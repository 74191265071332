import { Component, Input } from '@angular/core';
import { WorkflowService } from '../../services/workflow.service';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { IWorkflow, IVersionBase } from '../../interfaces';
import { RunWorkflowComponent } from '../run-workflow/run-workflow.component';
import { IActionList } from 'src/app/authentication/interfaces/permission.interface';
import { CreateWorkflowComponent } from 'src/app/shared/components/create-workflow/create-workflow.component';

@Component({
  selector: 'app-workflow-version-list',
  templateUrl: './workflow-version-list.component.html',
  styleUrls: ['./workflow-version-list.component.scss']
})
export class WorkflowVersionsComponent {
  bsModalRef: BsModalRef;
  searchWord; string;

  @Input()
  workflow: IWorkflow;

  @Input()
  versions: IVersionBase[];

  actionList: IActionList;

  constructor(
    private workflowService: WorkflowService,
    private router: Router,
    private modalService: BsModalService
  ) {
    this.actionList = {};

    this.workflowService.actionList.subscribe(value => {
      this.actionList = value;
    });
  }


  openWorkflow(key: string) {
    this.router.navigate([`/workflow-overview`, key], { queryParamsHandling: 'merge' });
  }

  runWorkflow(workflowKey: string, workflowVersion: IVersionBase) {
    const initialState = {
      workFlowKey: workflowKey,
      workFlowVersion: workflowVersion.version,
      inputSchema: workflowVersion.inputSchema
    };
    this.bsModalRef = this.modalService.show(RunWorkflowComponent, { initialState, class: 'gray modal-lg' });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  public openHistoryLogs(): void {
    this.router.navigate([`/workflow-overview`, this.workflow.key], { queryParamsHandling: 'merge' });
  }

  public cloneWorkflow(workflowVersion: IVersionBase) {
    this.workflowService.getWorkflowVersionNumber(this.workflow.key, workflowVersion.version)
      .toPromise()
      .then(({ xml }) => {
        const initialState = {
          title: `Clone Workflow From version ${workflowVersion.version}`,
          type: 'workflow', xml
        };
        this.bsModalRef = this.modalService.show(CreateWorkflowComponent, { initialState, class: 'gray modal-lg' });
        this.bsModalRef.content.closeBtnName = 'Close';
      });
  }

  public createTemplateFromWorkflow(workflowVersion: IVersionBase) {
    this.workflowService.getWorkflowVersionNumber(this.workflow.key, workflowVersion.version)
      .toPromise()
      .then(({ xml }) => {
        const initialState = {
          title: `Create Template From version ${workflowVersion.version}` ,
          type: 'template', xml
        };
        this.bsModalRef = this.modalService.show(CreateWorkflowComponent, { initialState, class: 'gray modal-lg' });
        this.bsModalRef.content.closeBtnName = 'Close';
      });
  }
}
