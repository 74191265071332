import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  body: string;
  initiator: string;
  confirmText: string;
  cancelText: string;
  public onClose: Subject<{initiator: string, confirm: boolean}>;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {

    this.onClose = new Subject();
    this.confirmText = this.confirmText || 'Confirm';
    this.cancelText = this.cancelText || 'Cancel';

  }

  confirm(): void {

    this.onClose.next({ initiator: this.initiator, confirm: true });
    this.bsModalRef.hide();
  }

  cancel(): void {

    this.onClose.next({ initiator: this.initiator, confirm: false });
    this.bsModalRef.hide();
  }
}
