import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { IWorkflow, IInputSchema, IWorkflowBase } from '../interfaces/workflow.interface';
import { IVersion, IVersionBase } from '../interfaces/version.interface';
import { IInstanceBase } from '../interfaces/instance.interface';
import { IActivityInstance } from '../interfaces/activityInstance.interface';
import { IExternalTaskLog } from '../interfaces/externalTaskLog.interface';
import { IIncident } from '../interfaces/incident.interface';
import { IInstanceVariable } from '../interfaces/instanceVariable.interface';
import { IOwner } from '../interfaces/owner.interface';
import { ISecretData, ITrigger } from '../interfaces';
import { IProcessVariable } from '../interfaces/processVariable.interface';
import { IUser } from 'src/app/shared/interfaces/user.interface';
import { IActionList } from 'src/app/authentication/interfaces/permission.interface';
import { ICustomTaskLogs } from '../interfaces/customTask.interface';

@Injectable()
export class WorkflowService {
  readonly url: string = environment.apiUrl;
  public showShareWith: BehaviorSubject<boolean>;
  public userSharedWith: BehaviorSubject<IUser>;
  public actionList: BehaviorSubject<IActionList>;

  constructor(private httpClient: HttpClient) {
    this.showShareWith = new BehaviorSubject<boolean>(false);
    this.userSharedWith = new BehaviorSubject<IUser>(undefined);
    this.actionList = new BehaviorSubject<IActionList>({});
  }

  public getWorkflows(
    creator?: string,
    sortBy: string = 'updatedAt',
    nameLike?: string,
    offset?: string,
    limit?: string
  ): Observable<IWorkflowBase[]> {
    let params = (new HttpParams())
      .set('offset', offset)
      .set('limit', limit)
      .append('select', 'key')
      .append('select', 'name')
      .append('select', 'creatorName')
      .append('select', 'updatedAt')
      .append('select', 'inputSchema');

    if (creator) params = params.set('creator', creator);
    if (nameLike) params = params.set('nameLike', nameLike);

    if (sortBy) {
      params = sortBy === 'name' ?
        params.set('sortBy', 'name').set('sortOrder', 'asc') : params.set('sortBy', sortBy).set('sortOrder', 'desc');
    }

    return <Observable<IWorkflow[]>> this.httpClient.get(`${this.url}/workflows`,
        { params }
      )
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  // get owners (end-users that created workflows)
  public getWorkflowOwners(): Observable<IOwner[]> {

    return <Observable<IOwner[]>> this.httpClient.get(`${this.url}/users`)
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public deleteWorkflow(key: string): Observable<IWorkflow> {

    return <Observable<IWorkflow>>this.httpClient.delete(`${this.url}/workflows/${key}`)
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public updateWorkflowXml(key: string, xml: string): Observable<IWorkflow> {

    return <Observable<IWorkflow>> this.httpClient.put<IWorkflow>(`${this.url}/workflows/${key}`, { xml })
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public updateWorkflowInputSchema(key: string, inputSchema?: string): Observable<IInputSchema|null> {

    if (inputSchema) {
      const parsedInputSchema = JSON.parse(inputSchema);

      return <Observable<IInputSchema>> this.httpClient.put<IInputSchema>(
        `${this.url}/workflows/${key}/input-schema`,
        parsedInputSchema
      )
      .pipe(
        map(() => parsedInputSchema),
        catchError(err => throwError(err))
      );
    }
    else {
      return <Observable<IInputSchema>> this.httpClient.delete<IInputSchema>(
        `${this.url}/workflows/${key}/input-schema`
      )
      .pipe(
        map(() => null),
        catchError(err => throwError(err))
      );
    }
  }

  public addWorkflow(xml: string): Observable<IWorkflow> {

    return <Observable<IWorkflow>>this.httpClient.post(`${this.url}/workflows`, { xml })
      .pipe(
        map(response => response),
        catchError(err => throwError(err)));
  }

  public getWorkflow(key: string): Observable<IWorkflow> {

    return <Observable<IWorkflow>>this.httpClient.get(`${this.url}/workflows/${key}`)
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public getWorkflowVersions(
    key: string,
    offset: number = 0,
    limit: number = 100,
    selectInputSchema?: boolean
  ): Observable<IVersionBase[]> {
    let params = (new HttpParams())
      .set('offset', offset.toString())
      .set('limit', limit.toString())
      .set('sortBy', 'version')
      .set('sortOrder', 'desc')
      .append('select', 'version')
      .append('select', 'tag');

      if(selectInputSchema) params = params.append('select', 'inputSchema');

    return <Observable<IVersion[]>> this.httpClient.get(`${this.url}/workflows/${key}/versions`, { params })
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public getWorkflowVersionNumber(key: string, version: number): Observable<IVersion> {

    return <Observable<IVersion>> this.httpClient.get(`${this.url}/workflows/${key}/versions/${version}`)
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public getVersionInstances(key: string, version: number, offset: number = 0, limit: number = 100): Observable<IInstanceBase[]> {
    const params = new HttpParams().set('offset', offset.toString()).set('limit', limit.toString());

    return <Observable<IInstanceBase[]>> this.httpClient.get(`${this.url}/workflows/${key}/versions/${version}/instances`, { params })
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public getInstancesById(key: string, version: number, instanceId: string): Observable<IInstanceBase> {
    return <Observable<IInstanceBase>> this.httpClient.get(`${this.url}/workflows/${key}/versions/${version}/instances/${instanceId}`)
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public cancelInstancesById(key: string, version: number, instanceId: string): Observable<IInstanceBase> {
    return <Observable<IInstanceBase>> this.httpClient.delete(`${this.url}/workflows/${key}/versions/${version}/instances/${instanceId}`)
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public deployWorkflow(key: string): Observable<IWorkflow> {

    return <Observable<IWorkflow>>this.httpClient.post(`${this.url}/workflows/${key}/versions`, {})
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public startInstance(key: string, version?: number, instanceName?: string, variables?: IProcessVariable[]): Observable<IInstanceBase[]> {

    return <Observable<IInstanceBase[]>> this.httpClient.post(`${this.url}/workflows/${key}/instances`, {
        version,
        instanceName,
        variables
      })
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public getActivityInstance(
    key: string,
    version: number,
    processInstanceId: string,
    offset?: string,
    limit?: string
  ): Observable<IActivityInstance[]> {

    const params = new HttpParams().set('offset', offset).set('limit', limit);

    return <Observable<IActivityInstance[]>>this.httpClient.get(
      `${this.url}/workflows/${key}/versions/${version}/instances/${processInstanceId}/activities`,
      { params }
    )
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public getInstanceVariables(
    key: string,
    version: number,
    processInstanceId: string,
    offset?: string,
    limit?: string
  ): Observable<IInstanceVariable[]> {

    const params = new HttpParams().set('offset', offset).set('limit', limit);

    return <Observable<IInstanceVariable[]>>this.httpClient.get(
      `${this.url}/workflows/${key}/versions/${version}/instances/${processInstanceId}/variables`,
      { params }
    )
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public getInstanceIncidents(
    key: string,
    version: number,
    processInstanceId: string,
    offset?: string,
    limit?: string
  ): Observable<IIncident[]> {

    const params = new HttpParams().set('offset', offset).set('limit', limit);

    return <Observable<IIncident[]>>this.httpClient.get(
      `${this.url}/workflows/${key}/versions/${version}/instances/${processInstanceId}/incidents`,
      { params }
    )
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public getAllExternalTaskLog(
    key: string,
    version: number,
    processInstanceId: string,
    offset?: string,
    limit?: string
  ): Observable<IExternalTaskLog[]> {

    const params = new HttpParams().set('offset', offset).set('limit', limit);

    return <Observable<IExternalTaskLog[]>>this.httpClient.get(
      `${this.url}/workflows/${key}/versions/${version}/instances/${processInstanceId}/external-task-logs`,
      { params }
    )
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public getSecretData(workflowKey: string): Observable<string[]> {

    return <Observable<string[]>>this.httpClient.get(`${this.url}/workflows/${workflowKey}/secrets`)
      .pipe(
        map(response => response),
        catchError(err => throwError(err)));
  }

  public addSecretKeyValue(workflowKey: string, dataKey: string, dataValue: string): Observable<ISecretData> {

    return <Observable<ISecretData>>this.httpClient.post(`${this.url}/workflows/${workflowKey}/secrets`, {
      key: dataKey,
      value: dataValue
    })
      .pipe(
        map(response => response),
        catchError(err => throwError(err)));
  }

  public deleteSecretKeyValue(workflowKey: string, dataKey: string): Observable<ISecretData> {

    return <Observable<ISecretData>>this.httpClient.delete(`${this.url}/workflows/${workflowKey}/secrets/${dataKey}`)
      .pipe(
        map(response => response),
        catchError(err => throwError(err)));
  }

  public getTriggers(key: string, triggerType: string): Observable<ITrigger[]> {

    return <Observable<ITrigger[]>>this.httpClient.get(`${this.url}/workflows/${key}/triggers/${triggerType}`)
      .pipe(
        map(response => response),
        catchError(err => throwError(err)));
  }

  public getTrigger(key: string, triggerType: string, triggerKey: string): Observable<ITrigger> {

    return <Observable<ITrigger>> this.httpClient.get(
        `${this.url}/workflows/${key}/triggers/${triggerType}/${triggerKey}`
      )
      .pipe(
        map(response => response),
        catchError(err => throwError(err)));
  }

  public addTrigger(
    key: string,
    triggerType: string,
    name: string,
    description?: string,
    workflowVersion?: IVersion
  ): Observable<ITrigger> {

    const dataToCreate: any = { name };

    if (workflowVersion) {
      dataToCreate.version = workflowVersion.version;
    }

    if (description && description !== '') {
      dataToCreate.description = description;
    }

    return <Observable<ITrigger>> this.httpClient.post(
        `${this.url}/workflows/${key}/triggers/${triggerType}`,
        dataToCreate
      )
      .pipe(
        map(response => response),
        catchError(err => throwError(err)));
  }

  public deleteTrigger(key: string, triggerType: string, triggerKey: string): Observable<ITrigger> {

    return <Observable<ITrigger>> this.httpClient.delete(
        `${this.url}/workflows/${key}/triggers/${triggerType}/${triggerKey}`
      )
      .pipe(
        map(response => response),
        catchError(err => throwError(err)));
  }

  public updateTrigger(
    key: string,
    triggerType: string,
    triggerKey: string,
    revoked?: boolean,
    name?: string,
    description?: string | null,
    workflowVersion?: Partial<IVersion>
  ): Observable<ITrigger> {

    return <Observable<ITrigger>> this.httpClient.patch(
      `${this.url}/workflows/${key}/triggers/${triggerType}/${triggerKey}`,
      {
        name, revoked, description,
        version: workflowVersion ? workflowVersion.version : undefined
      }
    )
    .pipe(
      map(response => response),
      catchError(err => throwError(err)));
  }

  public getWorkflowAssignedUsers(key: string, offset: number = 0, limit: number = 100): Observable<IUser[]> {
    const params = new HttpParams()
      .set('offset', offset.toString())
      .set('limit', limit.toString())
      .set('sortBy', 'name')
      .set('sortOrder', 'asc');

    return <Observable<IUser[]>> this.httpClient.get(`${this.url}/workflows/${key}/users/assigned`, { params })
      .pipe(
        map(users => {
          this.setUsersShortName(<IUser[]> users);

          return users;
        }),
        catchError(err => throwError(err))
      );
  }


  public getWorkflowNewUsers(key: string, nameLike?: string, offset: number = 0, limit: number = 100): Observable<IUser[]> {
    let params = new HttpParams()
      .set('offset', offset.toString())
      .set('limit', limit.toString())
      .set('sortBy', 'name')
      .set('sortOrder', 'asc');

    if (nameLike) params = params.set('nameLike', nameLike);

    return <Observable<IUser[]>> this.httpClient.get(`${this.url}/workflows/${key}/users/new`, { params })
      .pipe(
        map(users => {
          this.setUsersShortName(<IUser[]> users);

          return users;
        }),
        catchError(err => throwError(err))
      );
  }

  public unassignWorkflowUser(key: string, authId: string): Observable<IUser> {
    return <Observable<IUser>> this.httpClient.delete(`${this.url}/workflows/${key}/users/${authId}`)
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public assignUserToWorkflow(key: string, authId: string): Observable<IUser> {

    return <Observable<IUser>> this.httpClient.post(`${this.url}/workflows/${key}/users`, { authId })
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }


  private setUsersShortName(users: IUser[]) {
    for (const user of users) {
      user.name = user.name || user.username;
      const [firstName, lastName] = user.name.split(' ');
      const firstChar = firstName && firstName.length > 0 ? firstName.substring(0, 1).toUpperCase() : '';
      const lastChar = lastName && lastName.length > 0 ? lastName.substring(0, 1).toUpperCase() : '';
      user.shortName = firstChar + lastChar;
    }
  }

  public getCustomTaskLogs(
    workflowKey: string,
    version: number,
    instanceId: string,
    taskId: string,
    nextToken?: string
  ): Observable<ICustomTaskLogs> {
    let params = new HttpParams();

    if (nextToken) params = params.set('nextToken', nextToken);

    return <Observable<ICustomTaskLogs>> this.httpClient.get(
      `${this.url}/workflows/${workflowKey}/versions/${version}/instances/${instanceId}/tasks/${taskId}/logs`,
      { params }
    )
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

}
