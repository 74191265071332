import { Component, OnInit, NgZone } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  visible: boolean;
  type: string;
  body: string;
  time: number;
  faTimesCircle;
  faCheckCircle;

  constructor(
    private alertService: AlertService,
    private ngZone: NgZone
  ) { }

  ngOnInit() {

    this.visible = false;
    this.faCheckCircle = faCheckCircle;
    this.faTimesCircle = faTimesCircle;

    this.alertService.showAlert.subscribe(alert => {
      this.type = alert.type;
      this.body = alert.body;
      this.time = alert.time || 2;
      this.visible = true;

      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.ngZone.run(() => {
            this.visible = false;
          });
        }, (this.time * 1000));
      });
    });
  }

  close() {
    this.visible = false;
  }
}
