var inputOutputHelper = require('bpmn-js-properties-panel/lib/helper/InputOutputHelper');
var elementHelper = require('bpmn-js-properties-panel/lib/helper/ElementHelper');
var cmdHelper = require('bpmn-js-properties-panel/lib/helper/CmdHelper');
var getBusinessObject = require('bpmn-js/lib/util/ModelUtil').getBusinessObject;
var entryFactory = require('bpmn-js-properties-panel/lib/factory/EntryFactory');
var is = require('bpmn-js/lib/util/ModelUtil').is;
var updateBusinessObject = require('bpmn-js-properties-panel/lib/cmd/UpdateBusinessObjectHandler');
var updateBusinessObjectList = require('bpmn-js-properties-panel/lib/cmd/UpdateBusinessObjectListHandler');


function getInputParameters(element, insideConnector) {
  return inputOutputHelper.getInputParameters(element, insideConnector);
}

function getInputOutput(element, insideConnector) {
  return inputOutputHelper.getInputOutput(element, insideConnector);
}

function createInputOutput(parent, bpmnFactory, properties) {
  return createElement('camunda:InputOutput', parent, bpmnFactory, properties);
}

function createParameter(type, parent, bpmnFactory, properties) {
  return createElement(type, parent, bpmnFactory, properties);
}

function createElement(type, parent, factory, properties) {
  return elementHelper.createElement(type, properties, parent, factory);
}

function isMap(elem) {
  return is(elem, 'camunda:Map');
}

var getInputParameterByName = function (element, name) {

  var inputs = getInputParameters(element, false);

  var parameter;

  inputs.forEach(element => {
    if (element.name === name) {
      parameter = element;
    }
  });
  return parameter;
};

var createExtElements = function (element, bpmnFactory) {
  var bo = getBusinessObject(element);
  var extensionElements = bo.get('extensionElements');

  if (!extensionElements) {
    extensionElements = elementHelper.createElement('bpmn:ExtensionElements', { values: [] }, bo, bpmnFactory);
    var x = cmdHelper.updateBusinessObject(element, bo, { extensionElements: extensionElements });
    updateBusinessObject.prototype.execute(x.context);
  }
}

var initializeMap = function (element, definition, bpmnFactory) {
  createExtElements(element, bpmnFactory);

  var bo = getBusinessObject(element);
  var extensionElements = bo.get('extensionElements');
  var insideConnector = false;

  var inputOutput = getInputOutput(element, insideConnector);
  if (!inputOutput) {
    var parent = extensionElements;

    inputOutput = createInputOutput(parent, bpmnFactory, {
      inputParameters: [],
      outputParameters: []
    });

    var command = cmdHelper.addAndRemoveElementsFromList(
      element,
      extensionElements,
      'values',
      'extensionElements',
      [inputOutput],
      []
    );
    updateBusinessObjectList.prototype.execute(command.context);
  }

  var inputParam = getInputParameterByName(element, definition.binding.name);
  if (!inputParam) {

    var newElem = createParameter('camunda:InputParameter', inputOutput, bpmnFactory, { name: definition.binding.name });

    var cmd = cmdHelper.addElementsTolist(element, inputOutput, 'inputParameters', [newElem]);
    updateBusinessObjectList.prototype.execute(cmd.context);

    var properties = {
      value: undefined,
      definition: undefined
    };

    properties.definition = createElement('camunda:Map', newElem, bpmnFactory);

    var cmd = cmdHelper.updateBusinessObject(element, newElem, properties);
    updateBusinessObject.prototype.execute(cmd.context);
  }
};

module.exports = function (definition, element, bpmnFactory, translate) {

  var getSelected = function (element) {
    return getInputParameterByName(element, definition.binding.name);
  };

  initializeMap(element, definition, bpmnFactory);

  var tableEntry = entryFactory.table({
    id: 'parameterType-map-st',
    modelProperties: ['key', 'value'],
    labels: [translate('Key'), translate('Value')],
    addLabel: `Add ${definition.binding.name}`,

    getElements: function (element, node) {
      var bo = getSelected(element);

      if (bo && isMap(bo.definition)) {
        return bo.definition.entries;
      }

      return [];
    },

    updateElement: function (element, values, node, idx) {
      var bo = getSelected(element);
      var entry = bo.definition.entries[idx];

      if (isMap(entry.definition)) {
        values = {
          key: values.key
        };
      }

      return cmdHelper.updateBusinessObject(element, entry, values);
    },

    addElement: function (element, node) {
      var bo = getSelected(element);
      var newEntry = createElement('camunda:Entry', bo.definition, bpmnFactory, { key: undefined, value: undefined });
      return cmdHelper.addElementsTolist(element, bo.definition, 'entries', [newEntry]);
    },

    removeElement: function (element, node, idx) {
      var bo = getSelected(element);
      return cmdHelper.removeElementsFromList(element, bo.definition, 'entries', null, [bo.definition.entries[idx]]);
    },

    editable: function (element, node, prop, idx) {
      var bo = getSelected(element);
      var entry = bo.definition.entries[idx];
      return prop === 'key' || (!isMap(entry.definition));
    },

    setControlValue: function (element, node, input, prop, value, idx) {
      var bo = getSelected(element);
      var entry = bo.definition.entries[idx];

      if (prop === 'key' || (!isMap(entry.definition))) {
        input.value = value;
      } else {
        input.value = 'Map';
      }
    }

  });
  return tableEntry;
}