import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { WorkflowService } from '../services/workflow.service';
import { map, catchError } from 'rxjs/operators';
import { IWorkflow } from '../interfaces/workflow.interface';

@Injectable()
export class WorkflowResolver implements Resolve<IWorkflow> {

  constructor(private workflowService: WorkflowService, private route: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<IWorkflow> {

    return this.workflowService.getWorkflow(route.paramMap.get('key'))
      .pipe(
        map(workflow => workflow),
        catchError(() => {

          this.route.navigate(['/error-page'], { queryParamsHandling: 'merge' });
          return of(null);
        })
      );
  }
}
