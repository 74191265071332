import { Component, Input } from '@angular/core';
import { AlertService } from 'src/app/shared/services/alert.service';
import { IWorkflow } from '../../../interfaces';
import { WorkflowService } from '../../../services/workflow.service';
import { faPlusCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IUser } from 'src/app/shared/interfaces/user.interface';

@Component({
  selector: 'app-workflow-sharing',
  templateUrl: './sharing.component.html',
  styleUrls: ['./sharing.component.scss']
})
export class SharingComponent {
  @Input() workflow: IWorkflow;
  sharingOpened: boolean;
  searchWord: string;
  bsModalRef: BsModalRef;
  users: IUser[];
  faPlusCircle: IconDefinition;

  constructor(
    private workflowService: WorkflowService,
    private alertService: AlertService
  ) {
    this.sharingOpened = false;
    this.faPlusCircle = faPlusCircle;
    this.users = [];
  }

  public close(): void {
    this.sharingOpened = false;
  }

  public getFilteredUsers() {
    this.workflowService.getWorkflowNewUsers(this.workflow.key, this.searchWord).toPromise()
    .then((users) => {
      this.users = users;
    });
  }

  public addUser(authId: string) {
    this.workflowService.assignUserToWorkflow(this.workflow.key, authId).toPromise()
      .then(() => {
        this.alertService.create({ type: 'success', body: `User is Assigned successfully`, time: 3 });
        const deletedIndex = this.users.findIndex(ele => ele.authId === authId);
        this.workflowService.userSharedWith.next(this.users[deletedIndex]);
        this.users.splice(deletedIndex, 1);
      });
  }

}
