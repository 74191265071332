import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WorkflowService } from '../../services/workflow.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import {
  faCheckCircle, faSpinner, faTimesCircle, faPauseCircle, IconDefinition,
  faPlus, faMinus, faSync, faTimes, faClone, faCaretDown, faCaretRight
} from '@fortawesome/free-solid-svg-icons';
import {
  IActivityInstance, IInstanceVariable, IExternalTaskLog,
  IWorkflow, IVersionBase, IInstance, IIncident, IInstanceStatistics, IStatsPosition, IActivitySummary
} from '../../interfaces';
import BpmnNavigatedViewer from 'bpmn-js/dist/bpmn-navigated-viewer.production.min.js';
import { InstanceState } from '../../enums/instanceState.enum';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { IActionList } from 'src/app/authentication/interfaces/permission.interface';
import { DxDataGridComponent } from 'devextreme-angular';
import { ILogEvent, ICustomTaskStreams } from '../../interfaces/customTask.interface';

@Component({
  selector: 'app-workflow-overview',
  templateUrl: './workflow-overview.component.html',
  styleUrls: ['./workflow-overview.component.scss']
})
export class WorkflowOverviewComponent implements OnInit, OnDestroy {
  public workflow: IWorkflow;
  public selectedVersion?: IVersionBase;
  public selectedInstance?: IInstance;
  public instances: IInstance[];
  public versions: IVersionBase[];
  public activityInstances: IActivityInstance[];
  public filteredActivityInstance: IActivityInstance[];
  public filteredInstanceVariables: IInstanceVariable[];
  public instanceVariables: IInstanceVariable[];
  public instanceIncidents: IIncident[];
  public filteredInstanceIncidents: IIncident[];
  public filteredExternalTaskLogs: IExternalTaskLog[];
  public externalTaskLogs: IExternalTaskLog[];
  public customTasksLogs: ICustomTaskStreams;
  public maxLogsAutoLoadTimes: number;
  public instanceXml: string;
  public faCheckCircle: IconDefinition;
  public faTimesCircle: IconDefinition;
  public faTimes: IconDefinition;
  public faSpinner: IconDefinition;
  public faPauseCircle: IconDefinition;
  public faPlus: IconDefinition;
  public faMinus: IconDefinition;
  public faSync: IconDefinition;
  public faClone: IconDefinition;
  public faCaretDown: IconDefinition;
  public faCaretRight: IconDefinition;
  public currentTab: string;
  public viewer: BpmnNavigatedViewer;
  public auditColumnsTab: string[];
  public variablesColumnsTab: string[];
  public incidentsColumnsTab: string[];
  public externalTaskColumnsTab: string[];
  public eventBus;
  public bsModalRef: BsModalRef;
  public actionList: IActionList;
  public selectedActivity?: IActivitySummary;
  public loadingNewLogs: boolean;
  public loadingOldLogs: boolean;

  private limit = '100';
  private offset = '0';
  private versionsLimit: number;
  private versionsOffset: number;
  private instancesLimit: number;
  private instancesOffset: number;

  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  constructor(
    private route: ActivatedRoute,
    private workflowService: WorkflowService,
    private alertService: AlertService,
    private modalService: BsModalService
  ) {
    this.faCheckCircle = faCheckCircle;
    this.faTimesCircle = faTimesCircle;
    this.faPauseCircle = faPauseCircle;
    this.faSpinner = faSpinner;
    this.faTimes = faTimes;
    this.faPlus = faPlus;
    this.faMinus = faMinus;
    this.faSync = faSync;
    this.faClone = faClone;
    this.faCaretDown = faCaretDown;
    this.faCaretRight = faCaretRight;
    this.currentTab = 'auditLogTab';
    this.versionsLimit = 30;
    this.instancesLimit = 30;
    this.versionsOffset = 0;
    this.instancesOffset = 0;
    this.resetInstanceLogs();

    this.workflowService.actionList.subscribe(value => {
      this.actionList = value;
    });
  }

  ngOnDestroy(): void {
    this.selectedVersion = undefined;
    this.selectedInstance = undefined;
  }

  ngOnInit(): void {

    this.workflow = this.route.snapshot.data.workflow;
    this.versions = this.route.snapshot.data.versions;
    this.selectedVersion = this.versions[0];
    this.getVersionInstances(false);

    this.viewer = new BpmnNavigatedViewer({
      container: '#canvas'
    });

    this.eventBus = this.viewer.get('eventBus');
    this.eventBus.on('element.click', (e) => {
      this.resetScriptLogs();

      if (e.element.type === 'bpmn:Process') {
        this.selectedActivity = undefined;
      }
      else {
        const customTask = this.externalTaskLogs.find((extTask) => (extTask.activityId === e.element.id && extTask.topicName === 'custom-tasks'));
        this.selectedActivity = { activityId: e.element.id, isCustomTask: customTask ? true : false };
      }

      this.populateTabsWithData(this.currentTab);
    });

  }

  public getVersions(increase: boolean): void {
    this.versionsOffset = increase ? (this.versionsOffset + this.versionsLimit) : 0;

    this.workflowService.getWorkflowVersions(
      this.workflow.key,
      this.versionsOffset,
      this.versionsLimit
    )
      .toPromise()
      .then((requestedVersions) => {
        this.versions = this.versions.concat(requestedVersions);
      });
  }

  public getVersionInstances(increase: boolean): void {
    if (this.selectedVersion) {
      this.instancesOffset = increase ? (this.instancesOffset + this.instancesLimit) : 0;

      this.workflowService.getVersionInstances(
        this.workflow.key,
        this.selectedVersion.version,
        this.instancesOffset,
        this.instancesLimit
      ).toPromise()
        .then((requestedInstances) => {
          this.instances = this.instances || [];
          this.instances = requestedInstances.map(instance => this.mapInstanceStatus(instance));
          this.selectedInstance = this.instances[0];
          this.loadInstanceXml();
        })
        .catch(() => {
          this.instances = [];
        });
    }
  }


  private mapInstanceStatus(instance: IInstance) {
    const displayInstance: IInstance = instance;

    switch (instance.state) {
      case InstanceState.ACTIVE:
        displayInstance.icon = this.faSpinner;
        displayInstance.statusDisplay = 'In Progress';
        break;
      case InstanceState.COMPLETED:
        displayInstance.icon = this.faCheckCircle;
        displayInstance.statusDisplay = 'Completed';
        break;
      case InstanceState.SUSPENDED:
        displayInstance.icon = this.faPauseCircle;
        displayInstance.statusDisplay = 'Paused';
        break;
      default:
        displayInstance.icon = this.faTimesCircle;
        displayInstance.statusDisplay = 'Terminated';
        break;
    }

    return displayInstance;
  }

  public cancelInstance(instanceId: string): void {
    const modalOpts: ModalOptions = {
      initialState: {
        body: `Are you sure you want to cancel running instance ${instanceId}`,
        initiator: 'app-workflow-overview'
      },
      backdrop: 'static',
      ignoreBackdropClick: false,
      animated: true,
      keyboard: false
    };

    this.bsModalRef = this.modalService.show(ConfirmModalComponent, modalOpts);

    this.bsModalRef.content.onClose.subscribe(result => {

      if (result.initiator === 'app-workflow-overview' && result.confirm) {

        this.workflowService.cancelInstancesById(
          this.workflow.key,
          this.selectedVersion.version,
          instanceId
        )
        .toPromise()
        .then(() => {
          this.alertService.create({ type: 'success', body: `Instance ${instanceId} is Cancelled successfully`, time: 3 });
          const cancelledInstance = this.instances.find(ele => ele.id === instanceId);
          cancelledInstance.state = InstanceState.EXTERNALLY_TERMINATED;
          cancelledInstance.icon = this.faTimesCircle;
          cancelledInstance.statusDisplay = 'Terminated';
        });
      }
    });
  }

  public async loadInstanceXml(): Promise<void> {

    if (this.selectedInstance) {
      const version = await this.workflowService.getWorkflowVersionNumber(this.workflow.key, this.selectedVersion.version).toPromise();
      this.instanceXml = version.xml;

      this.viewer.importXML(this.instanceXml).then(() => {
        this.autoUpdateInstance();
      });
    }
    else {
      this.viewer.clear();
    }
  }

  public async autoUpdateInstance(): Promise<void> {

    if (this.selectedInstance) {
      await this.updateSelectedInstanceState();
      await Promise.all([ this.loadActivities(), this.loadIncidents(), this.loadExternalLogs() ]);

      this.viewer.get('canvas').zoom('fit-viewport');
      const overlays = this.viewer.get('overlays');
      const activitiesStatistics = this.getTaskStatistics();

      // tslint:disable-next-line: forin
      for (const activityId in activitiesStatistics) {

        if (activitiesStatistics[activityId].position) {
          overlays.add(activityId, 'note', {
            position: activitiesStatistics[activityId].position,
            html: `
              <div class="instances-overlay">
                ${activitiesStatistics[activityId].taskExecTimes ?
                  `<span class="badge" title="Execution Times">${activitiesStatistics[activityId].taskExecTimes}</span>` : ''}
                ${activitiesStatistics[activityId].incidents ?
                  `<span class="badge badge-important instance-incidents" title="Incidents">${activitiesStatistics[activityId].incidents}</span>` : ''}
              </div>
            `
          });
        }
      }

      setTimeout(() => {

        if (
          this.selectedInstance &&
          this.selectedInstance.state === InstanceState.ACTIVE &&
          !this.instanceIncidents.length
        ) {
          this.autoUpdateInstance();
        }
      }, 10000);

    }
    else {
      this.viewer.clear();
    }
  }


  private updateSelectedInstanceState() {
    return this.workflowService.getInstancesById(
      this.workflow.key,
      this.selectedVersion.version,
      this.selectedInstance.id
    )
    .toPromise()
    .then(instance => {
      this.selectedInstance = instance;
      const foundInstance = this.instances.find(ins => ins.id === this.selectedInstance.id);
      foundInstance.state = instance.state;
      this.mapInstanceStatus(foundInstance);
    });
  }

  private getTaskStatistics(): IInstanceStatistics {
    const activitiesStatistics: IInstanceStatistics = { };

    if (this.activityInstances) {
      for (const activity of this.activityInstances) {
        if (!activitiesStatistics[activity.activityId]) {
          activitiesStatistics[activity.activityId] = {
            incidents: 0, taskExecTimes: 0,
            position: this.getTaskStatsPosition(activity.activityId)
          };
        }

        ++activitiesStatistics[activity.activityId].taskExecTimes;
      }
    }

    if (this.instanceIncidents) {
      for (const incident of this.instanceIncidents) {
        if (!activitiesStatistics[incident.activityId]) {
          activitiesStatistics[incident.activityId] = {
            incidents: 0, taskExecTimes: 0,
            position: this.getTaskStatsPosition(incident.activityId)
          };
        }

        ++activitiesStatistics[incident.activityId].incidents;
      }
    }

    return activitiesStatistics;
  }

  private getTaskStatsPosition(activityId): IStatsPosition {
    if (activityId.includes('Activity')) {
      return { bottom: 22, right: 100 };
    }
    else if (activityId.includes('Gateway')) {
      return { bottom: 12, right: 37 };
    }
    else if (activityId.includes('Event')) {
      return { bottom: 12, right: 29 };
    }
  }

  public resetZoom(): void {
    this.viewer.get('canvas').zoom('fit-viewport', true);
  }

  public zoomOut(): void {
    this.viewer.get('zoomScroll').stepZoom(-1);
  }

  public zoomIn(): void {
    this.viewer.get('zoomScroll').stepZoom(1);
  }

  public loadInstanceInfo(tabId): void {

    this.currentTab = tabId;
    this.removeActiveClassForTabs(tabId);
    this.populateTabsWithData(tabId);
  }

  private resetInstanceLogs() {
    this.activityInstances = [];
    this.filteredActivityInstance = [];
    this.filteredInstanceVariables = [];
    this.instanceVariables = [];
    this.instanceIncidents = [];
    this.filteredInstanceIncidents = [];
    this.filteredExternalTaskLogs = [];
    this.externalTaskLogs = [];
    this.selectedActivity = undefined;
    this.resetScriptLogs();
  }

  private resetScriptLogs() {
    this.customTasksLogs = { events: [{ message: 'end' }] };
    this.loadingNewLogs = true;
    this.loadingOldLogs = false;
    this.maxLogsAutoLoadTimes = 0;
  }

  public toggleCustomTaskLogsAutoLoad(enable: boolean) {
    this.maxLogsAutoLoadTimes = enable ? 20 : 0;
    this.autoLoadCustomTaskLogs();
  }

  private autoLoadCustomTaskLogs() {
    if (this.maxLogsAutoLoadTimes && this.selectedActivity && this.selectedActivity.isCustomTask) {
      --this.maxLogsAutoLoadTimes;

      this.getCustomTaskLogs(true)
        .then(() => {
          const listItemNodes = document.getElementsByClassName('dx-data-row');

          setTimeout(() => {
            const scrollable = this.dataGrid.instance.getScrollable();

            if (scrollable)  scrollable.scrollToElement(listItemNodes[listItemNodes.length - 1]);
          }, 500);

          setTimeout(() => {
            this.autoLoadCustomTaskLogs();
          }, 5000);
        })
        .catch(() => {
          setTimeout(() => {
            this.autoLoadCustomTaskLogs();
          }, 5000);
        });
    }
  }

  public getCustomTaskLogs(latestLogs: boolean) {
    let nextToken;

    if (latestLogs) {
      nextToken = this.customTasksLogs.nextForwardToken;
      this.loadingNewLogs = true;
    }
    else {
      nextToken = this.customTasksLogs.nextBackwardToken;
      this.maxLogsAutoLoadTimes = 0;
      this.loadingOldLogs = true;
    }

    if (this.selectedActivity) {
      return this.workflowService.getCustomTaskLogs(
        this.workflow.key,
        this.selectedVersion.version,
        this.selectedInstance.id,
        this.selectedActivity.activityId,
        nextToken
      )
      .toPromise()
      .then((res) => {
        if (res) {
          if (latestLogs) {
            if (this.customTasksLogs.nextForwardToken === res.streams.nextForwardToken) {
              if (!res.inProgress) this.maxLogsAutoLoadTimes = 0;
            }
            else if (res.streams.events && res.streams.events.length) {
              this.addLatestLogs(res.streams);
            }
          }
          else {
            if (
              this.customTasksLogs.nextBackwardToken !== res.streams.nextBackwardToken &&
              res.streams.events && res.streams.events.length
            ) {
              this.addOldLogs(res.streams);
            }
          }
        }

        setTimeout(() => {
          this.loadingNewLogs = false;
          this.loadingOldLogs = false;
        }, 1000);
      });
    }
  }

  private mapCustomTaskLogs(logEvents: ILogEvent[], nextForwardToken?: string, nextBackwardToken?: string) {
    for (const logEvent of logEvents) {
      logEvent.message = `${(new Date(logEvent.timestamp)).toUTCString()}:  ${logEvent.message}`;
      logEvent.nextForwardToken = nextForwardToken;
      logEvent.nextBackwardToken = nextBackwardToken;
    }
  }

  private addLatestLogs(customTaskStreams: ICustomTaskStreams) {
    this.customTasksLogs.events.pop();
    const newTotalLength = this.customTasksLogs.events.length + customTaskStreams.events.length;

    if (!this.customTasksLogs.events.length) {
      this.customTasksLogs.events.push({ message: 'start' });
      this.customTasksLogs.nextBackwardToken = customTaskStreams.nextBackwardToken;
    }
    else if (newTotalLength >= 100) {
      const logsSizeToRemove = customTaskStreams.events.length;
      this.customTasksLogs.events = logsSizeToRemove <= 0 ?
        [{ message: 'start' }] : [{ message: 'start' }, ...this.customTasksLogs.events.slice(logsSizeToRemove - 1, this.customTasksLogs.events.length)];
      this.customTasksLogs.nextBackwardToken = this.customTasksLogs.events[1].nextBackwardToken;
    }

    this.mapCustomTaskLogs(customTaskStreams.events, customTaskStreams.nextForwardToken, customTaskStreams.nextBackwardToken);
    this.customTasksLogs.events.push(...customTaskStreams.events, { message: 'end' });
    this.customTasksLogs.nextForwardToken = customTaskStreams.nextForwardToken;
  }

  private addOldLogs(customTaskStreams: ICustomTaskStreams) {
    this.customTasksLogs.events.shift();
    const newTotalLength = this.customTasksLogs.events.length + customTaskStreams.events.length;

    if (newTotalLength >= 100) {
      const logsSizeToRemove = this.customTasksLogs.events.length - customTaskStreams.events.length;
      this.customTasksLogs.events = logsSizeToRemove <= 0 ?
        [{ message: 'end' }] : [...this.customTasksLogs.events.slice(0, logsSizeToRemove - 1), { message: 'end' }];
      this.customTasksLogs.nextForwardToken = this.customTasksLogs.events[this.customTasksLogs.events.length - 2].nextForwardToken;
    }

    this.mapCustomTaskLogs(customTaskStreams.events, customTaskStreams.nextForwardToken, customTaskStreams.nextBackwardToken);
    this.customTasksLogs.events = [{ message: 'start' }, ...customTaskStreams.events, ...this.customTasksLogs.events];
    this.customTasksLogs.nextBackwardToken = customTaskStreams.nextBackwardToken;
  }

  private checkRequestBefore(dataSource): boolean {
    return dataSource.some((log: any) => {
      return log.instanceId === this.selectedInstance.id;
    });
  }

  private setAllExternalTaskLog(): void {
    let requestedBefore = false;
    if (this.externalTaskLogs) {
      requestedBefore = this.checkRequestBefore(this.externalTaskLogs);
      if (this.filteredExternalTaskLogs) {
        this.filteredExternalTaskLogs = this.selectedActivity ? this.getExternalTaskLog() : this.externalTaskLogs;
      }
    }

    if (!requestedBefore) {
      this.loadExternalLogs();
    }
  }

  private loadExternalLogs() {
    return this.workflowService.getAllExternalTaskLog(
        this.workflow.key,
        this.selectedVersion.version,
        this.selectedInstance.id,
        this.offset,
        this.limit
      )
      .toPromise()
      .then((externalTaskLogs) => {
        this.externalTaskLogs = externalTaskLogs;
        this.filteredExternalTaskLogs = this.selectedActivity ? this.getExternalTaskLog() : this.externalTaskLogs;
      });
  }

  private getExternalTaskLog(): IExternalTaskLog[] {
    return this.externalTaskLogs.filter((task) => task.activityId === this.selectedActivity.activityId);
  }

  private setInstanceVariables() {

    let requestedBefore = false;
    if (this.instanceVariables) {
      requestedBefore = this.checkRequestBefore(this.instanceVariables);
      if (this.filteredInstanceVariables) {
        this.filteredInstanceVariables = this.selectedActivity ? this.getTaskVariables() : this.instanceVariables;
      }
    }

    if (!requestedBefore) {

      this.workflowService.getInstanceVariables(
          this.workflow.key,
          this.selectedVersion.version,
          this.selectedInstance.id,
          this.offset,
          this.limit
        ).toPromise()
        .then((variables) => {
          this.instanceVariables = variables;
          this.filteredInstanceVariables = this.selectedActivity ? this.getTaskVariables() : this.instanceVariables;
        });
    }
  }

  private getTaskVariables(): IInstanceVariable[] {
    return this.instanceVariables.filter((variable) => variable.activityId === this.selectedActivity.activityId);
  }


  private setInstanceIncidents(): void {

    let requestedBefore = false;

    if (this.instanceIncidents && this.instanceIncidents.length !== 0) {

      requestedBefore = this.checkRequestBefore(this.instanceIncidents);

      if (this.filteredInstanceIncidents) {
        this.filteredInstanceIncidents = this.selectedActivity ? this.getIncidentForTask() : this.instanceIncidents;
      }
    }

    if (!requestedBefore) {
      this.loadIncidents();
    }
  }

  private loadIncidents() {
    return this.workflowService.getInstanceIncidents(
        this.workflow.key,
        this.selectedVersion.version,
        this.selectedInstance.id,
        this.offset,
        this.limit
      )
      .toPromise()
      .then((incidents) => {
        this.instanceIncidents = incidents;
        this.filteredInstanceIncidents = this.selectedActivity ? this.getIncidentForTask() : this.instanceIncidents;
      });
  }

  private getIncidentForTask(): IIncident[] {
    return this.instanceIncidents.filter((incident) => incident.activityId === this.selectedActivity.activityId);
  }

  private setActivities(): void {
    let requestedBefore = false;

    if (this.activityInstances) {
      requestedBefore = this.checkRequestBefore(this.activityInstances);
      if (this.filteredActivityInstance) {
        this.filteredActivityInstance = this.selectedActivity ? this.getActivitiesForTask() : this.activityInstances;
      }
    }

    if (!requestedBefore) {
      this.loadActivities();
    }
  }

  private loadActivities() {
    return this.workflowService.getActivityInstance(
      this.workflow.key,
      this.selectedVersion.version,
      this.selectedInstance.id,
      this.offset,
      this.limit
    )
      .toPromise()
      .then((res) => {
        this.activityInstances = res;
        this.filteredActivityInstance = this.selectedActivity ? this.getActivitiesForTask() : this.activityInstances;
      });
  }

  private getActivitiesForTask(): IActivityInstance[] {
    return this.activityInstances.filter((activity) => activity.activityId === this.selectedActivity.activityId);
  }

  public onVersionChanged(version) {
    this.selectedVersion = version;
    this.instances = undefined;
    this.selectedInstance = undefined;

    const versionsItem = document.querySelectorAll('.version.list-group-item');
    versionsItem.forEach((item) => {
      item.classList.remove('active');
    });

    document.getElementById(version.version).classList.add('active');

    this.resetInstanceLogs();
    this.setDefaultTab();
    this.getVersionInstances(false);
  }

  private removeActiveClassForTabs(tabId: string) {
    const tabs = document.querySelectorAll('.nav-link');
    tabs.forEach((tab) => {
      tab.classList.remove('active');
    });

    document.getElementById(tabId).classList.add('active');
  }

  private setDefaultTab(): void {
    this.currentTab = 'auditLogTab';
    this.removeActiveClassForTabs('auditLogTab');
  }

  public onInstanceChanged(instance) {

    this.selectedInstance = instance;
    const instanceItems = document.querySelectorAll('.instance.list-group-item');
    instanceItems.forEach((item) => {
      item.classList.remove('active');
    });

    document.getElementById(instance.id).classList.add('active');

    this.resetInstanceLogs();
    this.setDefaultTab();
    this.loadInstanceXml();
  }

  public populateTabsWithData(tab: string) {
    this.maxLogsAutoLoadTimes = 0;

    if (this.selectedInstance) {
      if (tab === 'auditLogTab') {
        this.setActivities();
      }
      else if (tab === 'variablesTab') {
        this.setInstanceVariables();
      }
      else if (tab === 'externalTaskTab') {
        this.setAllExternalTaskLog();
      }
      else if (tab === 'incidentsTab') {
        this.setInstanceIncidents();
      }
      else if (this.currentTab === 'scriptLogs') {
        this.toggleCustomTaskLogsAutoLoad(true);
      }
    }
  }

  public copyValue(value: string) {
    const selBox = document.createElement('textarea');
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
