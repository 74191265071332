import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'my-work',
    loadChildren: () => import('./my-work/my-work.module').then(m => m.MyWorkModule)
  },
  {
    path: 'workflows',
    loadChildren: () => import('./workflow/workflow.module').then(m => m.WorkflowModule)
  },
  {
    path: 'templates',
    loadChildren: () => import('./template/template.module').then(m => m.TemplateModule)
  },
  {
    path: '',
    loadChildren: () => import('./workflow/workflow.module').then(m => m.WorkflowModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
