import { Component, OnInit } from '@angular/core';
import { faBan, IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  faBan: IconDefinition;
  constructor() {
    this.faBan = faBan;
  }

  ngOnInit(): void{
  }

}
