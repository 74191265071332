import { BrowserModule } from '@angular/platform-browser';
import { NgModule, DoBootstrap, ApplicationRef } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { AuthService } from './authentication/services/auth.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DashboardModule } from './dashboard/dashboard.module';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { HttpErrorInterceptor } from './shared/interceptors/http-error.interceptor';
import { DxDataGridModule } from 'devextreme-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModelerModule } from './dashboard/components/modeler/modeler.module';
import { DataSharingService } from './shared/services/data-sharing.service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    ModelerModule,
    BrowserModule,
    DxDataGridModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DashboardModule,
    SharedModule,
    FontAwesomeModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    KeycloakAngularModule
  ],
  providers: [
    DataSharingService,
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
  ],
  entryComponents: [AppComponent]
})
export class AppModule implements DoBootstrap {
  constructor(private keycloakService: KeycloakService, private httpClient: HttpClient) { }

  getRealm() {
    const realmRegex = new RegExp('([a-zA-Z0-9-]+)\.' + environment.baseDomain.replace(/\./g, '\\.'));
    const matchedGroups = window.location.host.match(realmRegex);

    return matchedGroups && matchedGroups.length ? matchedGroups[1] : environment.keycloak.realm;
  }

  async ngDoBootstrap(appRef: ApplicationRef) {
    const realm = this.getRealm();
    const keycloakConfig: any = await this.httpClient.get(`${environment.keycloak.url}/realms/${realm}/.well-known/openid-configuration`).toPromise();
    environment.tokenEndpoint = keycloakConfig.token_endpoint;

    await this.keycloakService
      .init({
        config: {
          url: environment.tokenEndpoint.match('(.+)\/realms')[1],
          realm,
          clientId: environment.keycloak.clientId
        },
        initOptions: {
          onLoad: 'login-required',
          checkLoginIframe: false
        },
        enableBearerInterceptor: true,
        bearerExcludedUrls: ['/assets', '/clients/public', 'amazonaws']
      });

    appRef.bootstrap(AppComponent);
  }
}
