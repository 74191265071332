import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { IComponentPermissions, IActionList } from 'src/app/authentication/interfaces/permission.interface';
import { WorkflowService } from '../services/workflow.service';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';

@Injectable()
export class WorkflowLogsGuard implements CanActivate {

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private workflowService: WorkflowService,
    private dataSharingService: DataSharingService
  ) { }

  async canActivate(): Promise<boolean> {
    try {
      const componentPermissions = this.getActionPermission();
      const permissions = await this.authService.getPermissions(componentPermissions.onLoad, componentPermissions.actions);

      this.setActionList(permissions);

      return true;
    }
    catch (error) {
      this.alertService.create({ type: 'error', body: 'Permission Denied', time: 8 });

      await this.authService.logout();

      return false;
    }
  }


  private getActionPermission(): IComponentPermissions {

    return {
      onLoad: { resource: 'workflows', scopes: ['read:own', 'read:all'] },
      actions: {
        'workflow-instances': ['delete'],
        'templates': ['read'],
        'workflows': ['create', 'read']
      }
    };
  }


  private setActionList(permissions: string[]): void {

    const actionList: IActionList = {
      deleteInstance: permissions.includes('workflow-instances#delete'),
      readTemplate: permissions.includes('templates#read'),
      createWorkflow: permissions.includes('workflows#create'),
      readWorkflow: permissions.includes('workflows#read:all') || permissions.includes('workflows#read:own')
    };

    this.workflowService.actionList.next(actionList);
    this.dataSharingService.actionList.next(actionList);
  }
}
