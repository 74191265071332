import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { WorkflowService } from '../../services/workflow.service';
import { AlertService } from '../../../../../shared/services/alert.service';
import { IProcessVariable } from '../../interfaces/processVariable.interface';
import { IInputSchema, IInputSchemaVariable } from '../../interfaces';

@Component({
  selector: 'app-run-workflow',
  templateUrl: './run-workflow.component.html',
  styleUrls: ['./run-workflow.component.scss']
})
export class RunWorkflowComponent implements OnInit, AfterViewInit {
  @ViewChild('variablesForm', { static: false }) variablesForm;

  workFlowKey: string;
  workFlowVersion: number;
  runWorkFlowForm: FormGroup;
  variables: FormArray;
  inputSchema?: IInputSchema;
  instanceVariables: Map<string, IProcessVariable>;
  validToRun: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private workflowService: WorkflowService,
    private alertService: AlertService
  ) {
    this.instanceVariables = new Map();
    this.validToRun = true;
  }

  ngOnInit() {
    this.runWorkFlowForm = this.formBuilder.group({ instanceName: [''] });
  }

  ngAfterViewInit(): void {
    this.validToRun = this.variablesForm ? this.variablesForm.isValid() : true;
  }

  onFormChange(form) {
    this.validToRun = this.variablesForm.isValid();
    const { key, value } = form.control;
    const schemaVariable = this.inputSchema.properties[key];
    const targetVariable = this.instanceVariables.get(key);

    if (targetVariable) {
      targetVariable.value = targetVariable.type === 'Date' ? this.parseDateValue(value) : value;
    }
    else {
      this.instanceVariables.set(key, this.parseVariable(key, value, schemaVariable));
    }
  }

  parseDateValue(value: Date) {
    return (new Date(value)).toISOString().replace('Z', '+0000');
  }

  parseVariable(variableName: string, value: any, schemaVariable: IInputSchemaVariable): IProcessVariable {
    const variable: IProcessVariable = { name: variableName, type: schemaVariable.type, value };

    switch (schemaVariable.type) {
      case 'boolean':
        variable.type = 'Boolean';
        break;
      case 'number':
        variable.type = 'Double';
        break;
      case 'date':
        variable.type = 'Date';
        variable.value = this.parseDateValue(value);
        break;
      default:
        variable.type = this.getStringSubType(schemaVariable.format);
        variable.value = variable.type === 'Date' ? this.parseDateValue(value) : value;
    }

    return variable;
  }

  getStringSubType(format?: string) {
    switch (format) {
      case 'json':
        return 'Json';
      case 'xml':
        return 'Xml';
      case 'date':
        return 'Date';
      default:
        return 'String';
    }
  }

  submit() {
    if(!this.validToRun) return;

    if (!this.variablesForm) {
      this.startInstance();
    }
    else {
      const body = this.runWorkFlowForm.value;

      if (body.instanceName === '') delete body.instanceName;

      this.startInstance(body.instanceName);
    }
  }


  startInstance(instanceName?: string) {
    this.workflowService.startInstance(
        this.workFlowKey,
        this.workFlowVersion,
        instanceName,
        [...this.instanceVariables.values()]
      )
      .toPromise()
      .then(() => {
        this.alertService.create({ type: 'success', body: 'the Instance is started successfully.', time: 3 });
        this.runWorkFlowForm.reset();
        this.bsModalRef.hide();
      })
      .catch(err => {
        this.runWorkFlowForm.reset();
        this.bsModalRef.hide();
      });
  }

}
