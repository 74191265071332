import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { RouterModule } from '@angular/router';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { HeaderComponent } from './components/header/header.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { AlertComponent } from './components/alert/alert.component';
import { AlertService } from './services/alert.service';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { ShortcutsModalComponent } from './components/shortcuts-modal/shortcuts-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CreateWorkflowComponent } from './components/create-workflow/create-workflow.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [NotFoundComponent, HeaderComponent, SidenavComponent, AlertComponent, ErrorPageComponent,
     ConfirmModalComponent, ShortcutsModalComponent, CreateWorkflowComponent],
  imports: [FontAwesomeModule, BsDropdownModule, CommonModule, RouterModule, BrowserAnimationsModule, NgbModule, ReactiveFormsModule ],
  entryComponents: [ConfirmModalComponent, ShortcutsModalComponent, CreateWorkflowComponent],
  providers: [AlertService],
  exports: [HeaderComponent, SidenavComponent, AlertComponent, ErrorPageComponent, ConfirmModalComponent, ShortcutsModalComponent]
})
export class SharedModule {

}

