import { Router, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { IComponentPermissions, IActionList } from 'src/app/authentication/interfaces/permission.interface';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
import { TaskService } from '../../components/modeler/services/tasks.service';

@Injectable()
export class MyWorkAccessGuard implements CanActivate {

  constructor(
    private router: Router,
    private alertService: AlertService,
    private authService: AuthService,
    private dataSharingService: DataSharingService,
    private tasksService: TaskService
  ) { }

  async canActivate(): Promise<boolean> {
    try {
      const componentPermissions = this.getActionPermission();
      const permissions = await this.authService.getPermissions(componentPermissions.onLoad, componentPermissions.actions);

      this.setActionList(permissions);

      return true;
    }
    catch (error) {
      this.alertService.create({ type: 'error', body: 'Permission Denied', time: 8 });

      this.router.navigate(['dashboard'], { queryParamsHandling: 'merge' });

      return false;
    }
  }


  private getActionPermission(): IComponentPermissions {

    return {
      onLoad: { resource: 'custom-tasks', scopes: ['read'] },
      actions: {
        'templates': ['read'],
        'workflows': ['read:all', 'read:own'],
        'custom-tasks': [
          'create', 'delete', 'read', 'update'
        ]
      }
    };
  }

  private setActionList(permissions: string[]): void {
    const actionList: IActionList = {
      createCustomTasks: permissions.includes('custom-tasks#create'),
      readCustomTasks: permissions.includes('custom-tasks#read'),
      updateCustomTasks: permissions.includes('custom-tasks#update'),
      deleteCustomTasks: permissions.includes('custom-tasks#delete'),
      readWorkflow: permissions.includes('workflows#read:all') || permissions.includes('workflows#read:own'),
      readTemplate: permissions.includes('templates#read')
    };

    this.dataSharingService.actionList.next(actionList);
    this.tasksService.actionList.next(actionList);
  }
}
