import { Component, OnInit, ViewChild, ElementRef, Input, AfterViewInit } from '@angular/core';
import propertiesPanelModule from 'bpmn-js-properties-panel';
import propertiesProviderModule from 'bpmn-js-properties-panel/lib/provider/camunda';
import camundaModdleDescriptor from 'camunda-bpmn-moddle/resources/camunda.json';
import Modeler from 'bpmn-js/lib/Modeler';
import { AlertService } from 'src/app/shared/services/alert.service';
import customModule from './custom/elements';
import CustomPropertiesProvider from './custom/properties';
import { faPlusCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { NewCustomTaskComponent } from './add-custom-task/add-custom-task.component';
import { TaskService } from 'src/app/dashboard/components/modeler/services/tasks.service';
import { ITaskDefinitionBase, ITaskDefinition } from 'src/app/shared/interfaces/taskDefinition.interface';
import { ActivatedRoute } from '@angular/router';
import { ICustomTask, INewCustomTask } from '../../my-work/components/custom-task/custom-task.interface';
import { IActionList } from 'src/app/authentication/interfaces/permission.interface';
@Component({
  selector: 'app-modeler',
  templateUrl: './modeler.component.html',
  styleUrls: ['./modeler.component.scss']
})
export class ModelerComponent implements OnInit, AfterViewInit {

  public faPlusCircle: IconDefinition;
  isFullscreen: boolean;
  customTasks: ITaskDefinition[];
  systemTasks: ITaskDefinition[];
  actionList: IActionList;
  hideSearch: boolean;

  @Input() xml: string;
  private modelerViewer: Modeler;

  eventBus: any;
  @ViewChild('canvas', { static: false }) container: ElementRef;

  @ViewChild(NewCustomTaskComponent, { static: false })
  private customTaskComponent: NewCustomTaskComponent;

  constructor(
    private alertService: AlertService,
    private taskService: TaskService,
    private activatedRoute: ActivatedRoute
  ) {
    this.faPlusCircle = faPlusCircle;
    this.systemTasks = [];
    this.customTasks = [];
    this.actionList = {};

    this.taskService.actionList.subscribe(value => {
      this.actionList = value;
    });
  }

  ngOnInit(): void {
    this.modelerViewer = new Modeler({
      container: '#canvas',
      width: '100%',
      height: '100%',
      keyboard: { bindTo: document },
      additionalModules: [
        propertiesPanelModule,
        propertiesProviderModule,
        CustomPropertiesProvider,
        customModule
      ],
      propertiesPanel: {
        parent: '#properties'
      },
      // needed if you'd like to maintain camunda:XXX properties in the properties panel
      moddleExtensions: {
        camunda: camundaModdleDescriptor
      },
      bpmnRenderer: {
        defaultFillColor: '#fff',
        defaultStrokeColor: '#000'
      }
    });

    this.isFullscreen = false;

    this.eventBus = this.modelerViewer.get('eventBus');
    this.openDiagram(this.xml);

    document.addEventListener('fullscreenchange', () =>
      this.isFullscreen = document.fullscreenElement ? true : false
    );
  }


  ngAfterViewInit(): void {
    this.eventBus.createEvent('custom-tasks');
    this.eventBus.createEvent('system-tasks');
    this.eventBus.fire('system-tasks', { definitions: this.activatedRoute.snapshot.data.systemTasks });

    this.listenOnEvent('search-task', (data) => {
      if (data.nameLike) {
        this.getCustomTasks(data.nameLike);
      }
    });

    this.listenOnEvent('request-custom-task-definition', (data) => {
      let foundDefinition: ITaskDefinition;

      foundDefinition = this.customTasks.find(def => {
        return def.taskId === data.taskId;
      });

      if (!foundDefinition) {
        this.getCustomTaskById(data.taskId, data.elementId);
      }
      else {
        this.eventBus.fire('task-definition', { definition: foundDefinition, elementId: data.elementId });
      }
    });

    this.listenOnEvent('request-system-task-definition', (data) => {
      let foundDefinition: ITaskDefinition;

      foundDefinition = this.systemTasks.find(def => {
        return def.taskId === data.taskId;
      });

      if (!foundDefinition) {
        this.getSystemTaskById(data.taskId, data.elementId);
      }
      else {
        this.eventBus.fire('task-definition', { definition: foundDefinition, elementId: data.elementId });
      }
    });
  }

  private openDiagram(xml: string): void {

    this.modelerViewer.importXML(xml).then()
      .catch(() => {
        this.alertService.create({ type: 'error', body: 'Error when loading diagram xml.', time: 8 });
      });
  }

  public getXml(): Promise<string> {

    return this.modelerViewer.saveXML({ format: true }).then(result => {
      return result.xml;
    })
      .catch(() => {
        this.alertService.create({ type: 'error', body: 'Error when saving diagram xml.', time: 8 });
      });
  }

  public getModelerViewer(): Modeler {
    return this.modelerViewer;
  }

  public listenOnEvent(eventName: string, callback): void {
    this.eventBus.on(eventName, callback);
  }

  public openTaskCreateForm() {
    this.customTaskComponent.isTaskFormOpened = true;
  }

  public getCustomTasks(nameLike: string): void {

    if (this.actionList.readCustomTasks) {
      this.taskService.getCustomTasks(nameLike).toPromise()
      .then((matchedDefinitions: ITaskDefinitionBase[]) => {
        this.eventBus.fire('custom-tasks', { definitions: matchedDefinitions, searchWord: nameLike });
      });
    }
    else {
      this.eventBus.fire('custom-tasks', { definitions: [], searchWord: nameLike });
    }
  }

  public getCustomTaskById(taskId: string, elementId: string): void {

    this.taskService.getCustomTaskById(taskId).toPromise()
      .then((definition: ICustomTask) => {

        this.customTasks.push(definition);
        this.eventBus.fire('task-definition', { definition, elementId });

      });
  }

  public getSystemTaskById(taskId: string, elementId: string): void {

    this.taskService.getSystemTaskById(taskId).toPromise()
      .then((definition: ITaskDefinition) => {

        this.systemTasks.push(definition);
        this.eventBus.fire('task-definition', { definition, elementId });
      });
  }

  createCustomTask(customTask: INewCustomTask) {
    this.taskService.createCustomTask(
      customTask.name, customTask.properties, customTask.fileName, customTask.fileSize,
      customTask.language, customTask.hasOutput,  customTask.description
    )
    .toPromise()
    .then((createdTask) => {
      return this.taskService.uploadCodeFile(createdTask.uploadUrl, customTask.file).toPromise();
    })
    .then(() => {
      this.customTaskComponent.reset();
    });
  }
}
