import inherits from 'inherits';
import PropertiesActivator from 'bpmn-js-properties-panel/lib/PropertiesActivator';
import idProps from 'bpmn-js-properties-panel/lib/provider/bpmn/parts/IdProps';
import nameProps from 'bpmn-js-properties-panel/lib/provider/bpmn/parts/NameProps';
import * as CamundaPropertiesProvider from 'bpmn-js-properties-panel/lib/provider/camunda/CamundaPropertiesProvider';
import DrawCustomProps from './parts/DrawCustomProps';
import { is } from 'bpmn-js/lib/util/ModelUtil';


function createCustomGroup(eventBus, definition, element, bpmnFactory, translate) {
  var customGroup = {
    id: 'custom',
    label: 'Task Config',
    entries: []
  };


  idProps(customGroup, element, translate);
  nameProps(customGroup, element, bpmnFactory, canvas, translate);

  DrawCustomProps(eventBus, customGroup, definition, element, bpmnFactory, translate);

  return customGroup;
}


function setTaskId(businessObject) {
  if (
    !businessObject.taskId &&
    businessObject.extensionElements &&
    businessObject.extensionElements.values &&
    businessObject.extensionElements.values.length &&
    businessObject.extensionElements.values[0].inputParameters &&
    businessObject.extensionElements.values[0].inputParameters.length
  ) {
    const truflowElement = businessObject.extensionElements.values[0].inputParameters.find(param => param.name === 'system:taskId');
    businessObject.taskId = truflowElement ? truflowElement.value : undefined;
  }
}

function getTabsImplementation(eventBus, bpmnFactory, canvas, elementRegistry, elementTemplates, translate, taskDefinition) {

  return function (element) {

    taskDefinition = taskDefinition && taskDefinition.elementId === element.id ? taskDefinition : undefined;

    PropertiesActivator.call(this, eventBus);

    const camundaPropertiesProvider = new CamundaPropertiesProvider(eventBus, canvas, bpmnFactory, elementRegistry, elementTemplates, translate);
    const oldTabs = camundaPropertiesProvider.getTabs(element);

    if (is(element, 'bpmn:ServiceTask') && element.businessObject.type === 'external') {
      setTaskId(element.businessObject);

      if (taskDefinition) {
        const [generalTab, ...otherTabs] = oldTabs;
        const generalTabGroups = generalTab.groups.slice(2);

        var customTab = {
          id: 'general',
          label: `${taskDefinition.name}${taskDefinition.deleted? ' [DELETED]' : ''}`,
          groups: [
            createCustomGroup(eventBus, taskDefinition, element, bpmnFactory, translate),
            ... generalTabGroups
          ]
        };

        return [customTab, ...otherTabs];
      }
      else {
        if (element.businessObject.topic === 'custom-tasks') {
          eventBus.fire('request-custom-task-definition', { taskId: element.businessObject.taskId, elementId: element.id });
        }
        else if (element.businessObject.topic === 'system-tasks') {
          eventBus.fire('request-system-task-definition', { taskId: element.businessObject.taskId, elementId: element.id });
        }
      }
    }
    else {
      return oldTabs;
    }
  };
}

export default function CustomPropertiesProvider(eventBus, bpmnFactory, canvas, elementRegistry, elementTemplates, translate) {

  eventBus.on('task-definition', (data) => {
    this.getTabs = [];
    const definition = { ...data.definition, elementId: data.elementId }
    this.getTabs = getTabsImplementation(eventBus, bpmnFactory, canvas, elementRegistry, elementTemplates, translate, definition);

    setTimeout(() => {
      eventBus.fire('elementTemplates.changed');
    }, 300);
  });

  this.getTabs = getTabsImplementation(eventBus, bpmnFactory, canvas, elementRegistry, elementTemplates, translate);
}

inherits(CustomPropertiesProvider, PropertiesActivator);
