import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IAlert } from '../interfaces/alert.interface';


@Injectable()
export class AlertService {

  showAlert: Subject<IAlert>;

  constructor() {
    this.showAlert = new Subject<IAlert>();
  }

  create(alert: IAlert): void {
    this.showAlert.next(alert);
  }
}
