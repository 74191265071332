import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WorkflowListComponent } from './workflow-list.component';
import { WorkflowsResolver } from './resolvers/workflows.resolver';
import { WorkflowResolver } from './resolvers/workflow.resolver';
import { AccessWorkflowComponent } from './components/access-workflow/access-workflow.component';
import { WorkflowOverviewComponent } from './components/workflow-overview/workflow-overview.component';
import { WorkflowVersionsResolver } from './resolvers/workflow-versions.resolver';
import { WorkflowAccessGuard } from './guards/workflow-access.guard';
import { WorkflowListGuard } from './guards/workflow-list.guard';
import { WorkflowLogsGuard } from './guards/workflow-logs.guard';
import { SystemTasksResolver } from '../../components/modeler/resolvers/system-tasks.resolver';

const routes: Routes = [
  {
    path: '',
    component: WorkflowListComponent,
    resolve: { workflows: WorkflowsResolver },
    canActivate: [WorkflowListGuard]
  },
  {
    path: 'edit-workflow/:key',
    component: AccessWorkflowComponent,
    resolve: { workflow: WorkflowResolver, systemTasks: SystemTasksResolver },
    canActivate: [WorkflowAccessGuard]
  },
  {
    path: 'workflow-overview/:key',
    component: WorkflowOverviewComponent,
    resolve: { workflow: WorkflowResolver, versions: WorkflowVersionsResolver },
    canActivate: [WorkflowLogsGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkflowListRoutingModule { }
