import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { WorkflowService } from '../services/workflow.service';
import { map, catchError } from 'rxjs/operators';
import { IVersionBase } from '../interfaces/version.interface';

@Injectable()
export class WorkflowVersionsResolver implements Resolve<IVersionBase[]> {

  constructor(private workflowService: WorkflowService, private route: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<IVersionBase[]> {

    return this.workflowService.getWorkflowVersions(route.paramMap.get('key'), 0, 30)
      .pipe(
        map(versions => versions),
        catchError(() => {

          this.route.navigate(['/error-page'], { queryParamsHandling: 'merge' });
          return of(null);
        })
      );
  }
}
