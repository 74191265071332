var entryFactory = require('bpmn-js-properties-panel/lib/factory/EntryFactory');
var { findInputParameter, findExtension } = require('bpmn-js-properties-panel/lib/provider/camunda/element-templates/Helper');
var elementHelper = require('bpmn-js-properties-panel/lib/helper/ElementHelper');
var cmdHelper = require('bpmn-js-properties-panel/lib/helper/CmdHelper');
var createInputParameter = require('bpmn-js-properties-panel/lib/provider/camunda/element-templates/CreateHelper').createInputParameter;
var getBusinessObject = require('bpmn-js/lib/util/ModelUtil').getBusinessObject;
var MapEntry = require('./MapEntry');
var inputOutputHelper = require('bpmn-js-properties-panel/lib/helper/InputOutputHelper');
var updateBusinessObjectList = require('bpmn-js-properties-panel/lib/cmd/UpdateBusinessObjectListHandler');
var updateBusinessObject = require('bpmn-js-properties-panel/lib/cmd/UpdateBusinessObjectHandler');

var propertiesValues = {};
var globalHiddenKeys = {};
var globalValidation = {};
var parentChildMap = {};

function updateVariableValue(property, value) {
  if (
    parentChildMap[property] &&
    (
      !propertiesValues[parentChildMap[property].key] ||
      propertiesValues[parentChildMap[property].key] !== parentChildMap[property].value
    )
  ) {
    delete propertiesValues[property]; 
  };

  if (value && value.length) {
    deleteDependencies(property);
    propertiesValues[property] = value;
  }
}

function deleteDependencies(property) {
  if (globalHiddenKeys[property]) {
    for (const propValue in globalHiddenKeys[property]) {
      for (const propKey of globalHiddenKeys[property][propValue]) {
        deleteDependencies(globalHiddenKeys[propKey])
      }

      delete propertiesValues[property];
    }
  }
}

function getInputParameters(element, insideConnector) {
  return inputOutputHelper.getInputParameters(element, insideConnector);
}

function getInputParameterByName(element, name) {

  var inputs = getInputParameters(element, false);

  var parameter;

  inputs.forEach(element => {
    if (element.name === name) {
      parameter = element;
    }
  });
  return parameter;
};

function removeInputParameter(element, propertiesNames) {
  const inputs = [];

  for (const propName of propertiesNames) {
    delete globalValidation[propName];
    const input = getInputParameterByName(element, propName);

    if (input) inputs.push(input);
  }

  if (inputs.length) {
    var inOut = inputOutputHelper.getInputOutput(element, false);
    var command = cmdHelper.removeElementsFromList(element, inOut, 'inputParameters', null, inputs);

    updateBusinessObjectList.prototype.execute(command.context);
  }
}

function removePrevInput(element, field) {
  const propertiesToDelete = [];

  Object.keys(globalHiddenKeys[field]).forEach((hiddenKey) => {
    if (propertiesValues[field] !== hiddenKey && globalHiddenKeys[field][hiddenKey]) {
      globalHiddenKeys[field][hiddenKey].forEach((prop) => {
        propertiesToDelete.push(prop);
      });
    }
  });

  if (propertiesToDelete.length) removeInputParameter(element, propertiesToDelete);
}

function objectWithKey(key, value) {
  var obj = {};

  obj[key] = value;

  return obj;
}

function getPropertyValue(element, property, binding) {
  var bo = getBusinessObject(element);

  // property
  if (binding.type === 'property') {

    var value = bo.get(binding.name);
    // return value; default to defined default
    return typeof value !== 'undefined' ? value : propertyValue;
  }

  if (binding.type === 'camunda:inputParameter') {

    var propertyValue = property.value || '';
    var inputOutput, ioParameter;

    inputOutput = findExtension(bo, 'camunda:InputOutput');

    if (!inputOutput) {
      // ioParameter cannot exist yet, return property value
      return propertyValue;
    }

    ioParameter = findInputParameter(inputOutput, binding);
    if (ioParameter) {
      return ioParameter.value || '';
    }

    return propertyValue;
  }
}

function propertyGetter(binding, property) {
  /* getter */
  return function get(element) {
    var value = getPropertyValue(element, property, binding);

    if (globalHiddenKeys.hasOwnProperty(binding.name)) {
      updateVariableValue(binding.name, value);
      removePrevInput(element, binding.name);
    }

    return objectWithKey(binding.name, value);
  };
}

function setPropertyValue(element, property, value, bpmnFactory, binding) {

  if (binding.type === 'property') {

    var moddlePropertyDescriptor = bo.$descriptor.propertiesByName[bindingName];

    var moddleType = moddlePropertyDescriptor.type;

    if (BASIC_MODDLE_TYPES.indexOf(moddleType) === -1) {
      throw new Error('cannot set moddle type <' + moddleType + '>');
    }

    if (moddleType === 'Boolean') {
      propertyValue = !!value;
    }
    else {
      if (moddleType === 'Integer') {
        propertyValue = parseInt(value, 10);

        if (isNaN(propertyValue)) {
          propertyValue = undefined;
        }
      } else {
        propertyValue = value;
      }
    }
    if (propertyValue !== undefined) {
      updates.push(cmdHelper.updateBusinessObject(
        element, bo, objectWithKey(bindingName, propertyValue)
      ));
    }
  }

  else if (binding.type === 'camunda:inputParameter') {

    var bo = getBusinessObject(element);

    var updates = [];

    var extensionElements;

    extensionElements = bo.get('extensionElements');

    if (!extensionElements) {
      extensionElements = elementHelper.createElement('bpmn:ExtensionElements', null, element, bpmnFactory);

      updates.push(cmdHelper.updateBusinessObject(element, bo, objectWithKey('extensionElements', extensionElements)));
    }

    var inputOutput,
      existingIoParameter,
      newIoParameter;

    inputOutput = findExtension(extensionElements, 'camunda:InputOutput');

    if (!inputOutput) {
      inputOutput = elementHelper.createElement('camunda:InputOutput', null, bo, bpmnFactory);
      updates.push(cmdHelper.addElementsTolist(element, extensionElements, 'values', inputOutput));
    }

    existingIoParameter = findInputParameter(inputOutput, binding);

    newIoParameter = createInputParameter(binding, value, bpmnFactory);

    updates.push(cmdHelper.addAndRemoveElementsFromList(
      element,
      inputOutput,
      'inputParameters',
      null,
      [newIoParameter],
      existingIoParameter ? [existingIoParameter] : []
    ));

    if (updates.length) {
      return updates;
    }
  }
}

function inputParameterSetter(binding, property, bpmnFactory) {

  /* setter */
  return function set(element, values) {

    var value = values[binding.name];

    if (!value) {
      removeInputParameter(element, [binding.name]);
      return [];
    }
    else {
      if (Object.keys(globalHiddenKeys).includes(binding.name)) {
        updateVariableValue(binding.name, value);
        removePrevInput(element, binding.name);
      }
      return setPropertyValue(element, property, value, bpmnFactory, binding);
    }
  };
}

function isExist(str) {
  return str || str !== '' ? true : false;
}

function containsSpace(value) {
  return /\s/.test(value);
}

function isSecretValue(str) {
  return /^(\s*\{\{\s*secrets\.)([a-zA-Z0-9_]+)(\s*\}\}\s*$)/.test(str);
}

function isVariableName(str) {
  return /^(\$\s*\{\s*.+\}\s*$)/.test(str);
}

function validateStringFormat(value, format) {
  switch (format) {
    case 'json':
      try {
        JSON.parse(value)
      }
      catch (err) {
        return `Must be valid json`;
      }
      break;
    case 'url':
      try {
        new URL(value)
      }
      catch (err) {
        return `Must be valid url`;
      }
      break;
    case 'identifier':
      if (containsSpace(value)) return 'Must not contain spaces';
      
      break;
  }
}

function validateStringLength(value, maxLength, minLength) {
  if (maxLength && value.length > maxLength) return `Must have max length ${maxLength}`;

  if (minLength && value.length > maxLength) return `Must have min length ${minLength}`;
}

function validateNumberSize(value, min, max) {
  if (max && value > max) return `Must be number < ${max}`;

  if (min && value < min) return `Must be number > ${min}`;
}

function validateValue(value, property) {

  if (!isSecretValue(value) && !isVariableName(value)) {

    var constraints = property.constraints || {};

    if (constraints.required && !isExist(value)) {
      return 'Must not be empty';
    }

    // validate constraints only if there is a value
    if (value && value.length > 0) {
      switch (constraints.type) {
        case 'string':
          if (typeof value !== 'string') return `Must be valid string`;
          
          var formatError = validateStringFormat(value, constraints.format);
          var lengthError = validateStringLength(value, constraints.maxLength, constraints.minLength);
  
          return formatError || lengthError;
  
        case 'number':
          const numValue = Number(value);
  
          if (isNaN(numValue)) return `Must be valid number`;
          
          return validateNumberSize(numValue, constraints.min, constraints.max);
  
        case 'boolean':
          if (!['true', 'false'].includes(value)) return `Must be valid boolean`;
          
          break;
      }
    }
  }
}

function propertyValidator(property, eventBus) {
  /* validator */
  return function validate(element, values) {

    var value = values[property.binding.name];

    if (!ifElementHidden(property.binding.name)) {

      var error = validateValue(value, property);

      if (error) {
        globalValidation[property.binding.name] = false;
        return objectWithKey(property.binding.name, error);
      }
      else {
        globalValidation[property.binding.name] = true;
      }

      var allIsValid = Object.values(globalValidation).includes(false) ? false : true;
      var taskName = getBusinessObject(element).name
      eventBus.fire('validPanel', { validPanel: allIsValid, elementId: element.id, elementName: taskName });
    }

  };
}

function ifElementHidden(key) {

  var optionName = '';
  var propertyName = '';

  for (var hiddenParent in globalHiddenKeys) {

    for (var hiddenValueChild in globalHiddenKeys[hiddenParent]) {
      if (globalHiddenKeys[hiddenParent][hiddenValueChild].includes(key)) {
        optionName = hiddenValueChild;
        propertyName = hiddenParent;
      }
    }
  }

  return optionName ? (propertiesValues[propertyName] !== optionName) : false;
}

function createInputParam(element, value, bpmnFactory, binding){

  var bo = getBusinessObject(element);

  var extensionElements = bo.get('extensionElements');

  if (!extensionElements) {
    extensionElements = elementHelper.createElement('bpmn:ExtensionElements', null, element, bpmnFactory);
    var cmd = cmdHelper.updateBusinessObject(element, bo, objectWithKey('extensionElements', extensionElements));
    updateBusinessObject.prototype.execute(cmd.context);
  }

  var inputOutput,
    existingIoParameter,
    newIoParameter;

  inputOutput = findExtension(extensionElements, 'camunda:InputOutput');

  if (!inputOutput) {
    inputOutput = elementHelper.createElement('camunda:InputOutput', null, bo, bpmnFactory);
    var cmd = cmdHelper.addElementsTolist(element, extensionElements, 'values', inputOutput);
    updateBusinessObjectList.prototype.execute(cmd.context);
  }

  //existingIoParameter = findInputParameter(inputOutput, binding);
  existingIoParameter = getInputParameterByName(element, binding.name);

  newIoParameter = createInputParameter(binding, value, bpmnFactory);

  var cmd = cmdHelper.addAndRemoveElementsFromList(
    element,
    inputOutput,
    'inputParameters',
    null,
    [newIoParameter],
    existingIoParameter ? [existingIoParameter] : []
  );
  updateBusinessObjectList.prototype.execute(cmd.context);
}

function sortPropsDependencies(properties) {
  const propertiesKeys = new Set();
  
  for (const key in properties) {
    if (properties[key].visibleIf) {
      const children = [key];
      let parent = properties[key].visibleIf;
      let child = key;

      while (parent) {
        if (children.includes(parent.key) || !properties[parent.key]) { // circular
          parent = undefined;
          parentChildMap[child] = parent;
        }
        else {
          children.unshift(parent.key);
          parentChildMap[child] = parent;
          child = parent.key;
          parent = properties[parent.key].visibleIf || undefined;
        }
      }

      children.forEach(ch => propertiesKeys.add(ch));
    }
    else {
      parentChildMap[key] = undefined;
      propertiesKeys.add(key);
    }
  }

  return [...propertiesKeys.keys()];
}


module.exports = function (eventBus, group, definition, element, bpmnFactory, translate) {
  propertiesValues = {};
  globalHiddenKeys = {};
  globalValidation = {};
  parentChildMap = {};

  if (!definition.properties) definition.properties = {};
 
  Object.assign(definition.properties, { 
    ['system:taskId']: { value: definition.taskId, disabled: true } 
    },
    definition.hasOutput ? {
      ['system:taskOutput']: {
        label: 'output name',
        inputType: 'text',
        constraints: {
          type: 'string',
          format: 'identifier',
          maxLength: 40
        }
      }
    } : {}
  );

  const sortedPropertiesKeys = sortPropsDependencies(definition.properties);

  sortedPropertiesKeys.forEach((key) => {

    var elementDefinition = definition.properties[key];
    elementDefinition.binding = elementDefinition.binding || { type: 'camunda:inputParameter', name: key }
    var elementEntry;

    var disabledFunc = function () {
      return false;
    };

    var hiddenFunc = function () {
      return false;
    }

    if (elementDefinition.disabled) {
      disabledFunc = function () {
        return true;
      }
    }

    if (elementDefinition.visibleIf) {
      var hiddenKey = elementDefinition.visibleIf.key;
      var hiddenValue = elementDefinition.visibleIf.value;

      if (globalHiddenKeys[hiddenKey]) {

        if (globalHiddenKeys[hiddenKey][hiddenValue]) {
          if (!globalHiddenKeys[hiddenKey][hiddenValue].includes(elementDefinition.binding.name)) {
            globalHiddenKeys[hiddenKey][hiddenValue].push(elementDefinition.binding.name);
          }
        }
        else {
          globalHiddenKeys[hiddenKey][hiddenValue] = [elementDefinition.binding.name];
        }
      }
      else {
        globalHiddenKeys[hiddenKey] = {};
        globalHiddenKeys[hiddenKey][hiddenValue] = [elementDefinition.binding.name];
      }


      hiddenFunc = function () {
        return propertiesValues[hiddenKey] !== hiddenValue;
      }
    }

    if (elementDefinition.inputType === 'map') {
      elementEntry = MapEntry(elementDefinition, element, bpmnFactory, translate);
    }
    else {

      var existingIoParameter = getInputParameterByName(element, elementDefinition.binding.name);
      if (elementDefinition.value && !existingIoParameter) {
        createInputParam(element, elementDefinition.value, bpmnFactory, elementDefinition.binding);
      }

      var elementConfig = {
        id: elementDefinition.binding.name,
        label: elementDefinition.label,
        value: elementDefinition.value ? elementDefinition.value : '',
        modelProperty: elementDefinition.binding.name,
        get: propertyGetter(elementDefinition.binding, element),
        set: inputParameterSetter(elementDefinition.binding, element, bpmnFactory),
        disabled: disabledFunc,
        hidden: hiddenFunc,
        show: () => !hiddenFunc(),
        validate: propertyValidator(elementDefinition, eventBus)
      };

      if (elementDefinition.inputType === 'text') {
        elementEntry = entryFactory.textField(elementConfig);
      }
      else if (elementDefinition.inputType === 'textArea') {
        elementEntry = entryFactory.textBox(elementConfig);
      }
      else if (elementDefinition.inputType === 'selectBox') {
        elementConfig.selectOptions = elementDefinition.options;
        elementEntry = entryFactory.selectBox(elementConfig);
      }
    }

    if (elementEntry) group.entries.push(elementEntry);
  });

  return group;
}