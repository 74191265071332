import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { WorkflowModule } from './workflow/workflow.module';
import { MyWorkModule } from './my-work/my-work.module';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MyWorkModule,
    WorkflowModule,
    ReactiveFormsModule,
    BsDropdownModule,
    DashboardRoutingModule
  ],
  providers: [],
  entryComponents: [],
  exports: []
})
export class DashboardModule { }
