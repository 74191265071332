import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModelerComponent } from './modeler.component';
import { SidebarModule } from 'ng-sidebar';
import { NewCustomTaskComponent } from './add-custom-task/add-custom-task.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SystemTasksResolver } from './resolvers/system-tasks.resolver';
import { TaskService } from './services/tasks.service';

@NgModule({
  declarations: [
    ModelerComponent,
    NewCustomTaskComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule,
    FontAwesomeModule,
    NgxDropzoneModule,
    NgbModule,
    SidebarModule.forRoot()
  ],
  providers: [SystemTasksResolver, TaskService],
  entryComponents: [ModelerComponent],
  exports: [ModelerComponent, NewCustomTaskComponent]
})
export class ModelerModule { }
