import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { WorkflowListRoutingModule } from './workflow-list.routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WorkflowListComponent } from './workflow-list.component';
import { WorkflowService } from './services/workflow.service';
import { WorkflowsResolver } from './resolvers/workflows.resolver';
import { WorkflowFilterPipe } from './pipes/workflow-filter.pipe';
import { AccessWorkflowComponent } from './components/access-workflow/access-workflow.component';
import { WorkflowOverviewComponent } from './components/workflow-overview/workflow-overview.component';
import { WorkflowResolver } from './resolvers/workflow.resolver';
import { WorkflowVersionsResolver } from './resolvers/workflow-versions.resolver';
import { DxDataGridModule } from 'devextreme-angular';
import { SidebarModule } from 'ng-sidebar';
import { SearchFilterPipe } from './search-filter.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RunWorkflowComponent } from './components/run-workflow/run-workflow.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SettingsComponent } from './components/access-workflow/settings/settings.component';
import { TemplateResolver } from '../../template/template-list/resolvers/template.resolver';
import { TemplateService } from '../../template/template-list/services/template.service';
import { SharingComponent } from './components/access-workflow/sharing/sharing.component';
import { ModelerModule } from '../../components/modeler/modeler.module';
import { WorkflowAccessGuard } from './guards/workflow-access.guard';
import { WorkflowListGuard } from './guards/workflow-list.guard';
import { WorkflowLogsGuard } from './guards/workflow-logs.guard';
import { WorkflowVersionsComponent } from './components/workflow-version-list/workflow-version-list.component';
import { JsonFormBootstrap4Module, JsonFormBootstrap4, JsonFormModule } from '@trufla/ngx-tru-forms';

@NgModule({
  declarations: [
    WorkflowListComponent,
    WorkflowFilterPipe,
    AccessWorkflowComponent,
    SettingsComponent,
    SharingComponent,
    WorkflowOverviewComponent,
    SearchFilterPipe,
    RunWorkflowComponent,
    WorkflowVersionsComponent
  ],
  imports: [
    ModelerModule,
    WorkflowListRoutingModule,
    CommonModule,
    RouterModule,
    DxDataGridModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule,
    FontAwesomeModule,
    SidebarModule.forRoot(),
    NgbModule,
    InfiniteScrollModule,
    ModalModule,
    JsonFormBootstrap4Module,
    JsonFormModule.forRoot(JsonFormBootstrap4Module)
  ],
  providers: [
    WorkflowService,
    WorkflowsResolver,
    WorkflowResolver,
    WorkflowVersionsResolver,
    TemplateResolver,
    TemplateService,
    WorkflowAccessGuard,
    WorkflowListGuard,
    WorkflowLogsGuard,
    JsonFormBootstrap4
  ],
  entryComponents: [WorkflowListComponent, RunWorkflowComponent],
  exports: [WorkflowListComponent]
})
export class WorkflowListModule { }
