import { Component, OnInit } from '@angular/core';
import { faUsers, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { IUser } from '../../interfaces/user.interface';
import { AuthService } from 'src/app/authentication/services/auth.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})

export class SidenavComponent implements OnInit {

  faUsers: IconDefinition;
  user: IUser;

  constructor(private authService: AuthService) {
  }

  async ngOnInit(): Promise<void> {
    this.faUsers = faUsers;
    this.user = await this.authService.getUser()
  }

}
