import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { ITaskDefinition, ITaskDefinitionBase } from '../../../../shared/interfaces/taskDefinition.interface';
import { ICustomTask, ICustomTaskBase, IUpdateCustomTaskFile } from '../../../my-work/components/custom-task/custom-task.interface';
import { IActionList } from 'src/app/authentication/interfaces/permission.interface';

@Injectable()
export class TaskService {

  readonly url: string = environment.apiUrl;
  actionList: BehaviorSubject<IActionList>;

  constructor(private httpClient: HttpClient) {
    this.actionList = new BehaviorSubject<IActionList>({});
  }

  public getSupportedLanguages(): Observable<string[]> {
    return <Observable<string[]>> this.httpClient.get(`${this.url}/custom-tasks/languages`)
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public getSystemTasks(): Observable<ITaskDefinitionBase[]> {

    return <Observable<ITaskDefinitionBase[]>> this.httpClient.get(`${this.url}/system-tasks`)
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public getSystemTaskById(taskId: string): Observable<ITaskDefinition> {

    return <Observable<ITaskDefinition>> this.httpClient.get(`${this.url}/system-tasks/${taskId}`, {})
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public getCustomTasks(
    nameLike?: string,
    owned: boolean = false,
    offset: number = 0,
    limit: number = 100
  ): Observable<ICustomTaskBase[]> {

    let params = new HttpParams()
      .set('owned', owned.toString())
      .set('offset', offset.toString())
      .set('limit', limit.toString())
      .append('select', 'taskId')
      .append('select', 'name')
      .append('select', 'description')
      .append('select', 'createdAt');

    if (nameLike) params = params.set('nameLike', nameLike);

    return <Observable<ICustomTaskBase[]>> this.httpClient.get(`${this.url}/custom-tasks`, { params })
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public getCustomTaskById(taskId: string): Observable<ICustomTask> {

    return <Observable<ICustomTask>> this.httpClient.get(`${this.url}/custom-tasks/${taskId}`, {})
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public createCustomTask(
    name: string,
    properties: ITaskDefinition['properties'],
    fileName: string,
    fileSize: number,
    language: string,
    hasOutput: boolean,
    description?: string
  ): Observable<IUpdateCustomTaskFile> {
    if (!description) description = null;
    if (!properties) properties = null;

    return <Observable<IUpdateCustomTaskFile>> this.httpClient.post(`${this.url}/custom-tasks`, {
        name, properties, fileName, fileSize, language, description, hasOutput
      })
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public updateCustomTask(
    taskId: string,
    name?: string,
    properties?: ITaskDefinition['properties'],
    fileName?: string,
    fileSize?: number,
    language?: string,
    hasOutput?: boolean,
    description?: string
  ): Observable<IUpdateCustomTaskFile> {
    if (!description) description = null;
    if (!properties) properties = null;

    return <Observable<IUpdateCustomTaskFile>> this.httpClient.patch(`${this.url}/custom-tasks/${taskId}`, {
        name, properties, fileName, fileSize, language, description, hasOutput
      })
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public deleteCustomTask(
    taskId: string
  ): Observable<ICustomTask> {

    return <Observable<ICustomTask>> this.httpClient.delete(`${this.url}/custom-tasks/${taskId}`)
      .pipe(
        map(response => response),
        catchError(err => throwError(err))
      );
  }

  public getCustomTaskCodeFile(taskId: string): Promise<void> {

    return this.httpClient.get(`${this.url}/custom-tasks/${taskId}/code-file`)
      .toPromise()
      .then(res =>  (<any> res).downloadLink)
      .then(downloadLink =>  this.httpClient.get(downloadLink, { responseType: 'blob' }).toPromise())
      .then((data: BlobPart) => {
        const blob = new Blob([data], { type: 'application/zip' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
  }

  uploadCodeFile(s3UploadUrl: string, fileToUpload: File): Observable<boolean> {
    return this.httpClient
      .put(s3UploadUrl, fileToUpload)
      .pipe(
        map(() => true),
        catchError(err => throwError(err))
      );
  }
}
