import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { WorkflowService } from '../../../dashboard/workflow/workflow-list/services/workflow.service';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TemplateService } from 'src/app/dashboard/template/template-list/services/template.service';

@Component({
  selector: 'app-create-workflow',
  templateUrl: './create-workflow.component.html',
  styleUrls: ['./create-workflow.component.scss']
})
export class CreateWorkflowComponent implements OnInit {
  title?: string;
  type: string;
  newWorkflowForm: FormGroup;
  xml: string;
  defaultXml: string;

  constructor(
    public bsModalRef: BsModalRef,
    private workflowService: WorkflowService,
    private templateService: TemplateService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.defaultXml = `
      <?xml version="1.0" encoding="UTF-8"?>
      <bpmn:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
          xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL"
          xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI"
          xmlns:dc="http://www.omg.org/spec/DD/20100524/DC"
          targetNamespace="http://bpmn.io/schema/bpmn" id="Definitions_1"
        >
        <bpmn:process id="" isExecutable="true" name="">
          <bpmn:startEvent id="StartEvent_1"/>
        </bpmn:process>
        <bpmndi:BPMNDiagram id="BPMNDiagram_1">
          <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1">
            <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
              <dc:Bounds height="36.0" width="36.0" x="173.0" y="102.0"/>
            </bpmndi:BPMNShape>
          </bpmndi:BPMNPlane>
        </bpmndi:BPMNDiagram>
      </bpmn:definitions>
    `;
  }

  ngOnInit(): void {
    this.newWorkflowForm = this.formBuilder.group({
      name: ['', Validators.required]
    });
  }

  submit() {
    if (!this.newWorkflowForm.invalid) {
      let xml = this.xml || this.defaultXml;
      xml = xml.replace(/name=\".*?"/, `name="${this.newWorkflowForm.value.name}"`);

      if (this.type === 'workflow') {
        this.createNewWorkflow(xml);
      }
      else if (this.type === 'template') {
        this.createNewTemplate(xml);
      }
    }
  }

  createNewWorkflow(xml) {
    this.workflowService.addWorkflow(xml).toPromise()
      .then((workflow) => {
        this.newWorkflowForm.reset();
        this.bsModalRef.hide();

        this.router.navigate([`/edit-workflow`, workflow.key], { queryParamsHandling: 'merge' });
      });
  }

  createNewTemplate(xml) {
    this.templateService.addTemplate(xml).toPromise()
      .then((workflow) => {
        this.newWorkflowForm.reset();
        this.bsModalRef.hide();

        this.router.navigate([`/templates/edit-template`, workflow.key], { queryParamsHandling: 'merge' });
      });
  }
}
