import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  faSearch, IconDefinition, faPlus, faEye, faEdit, faTimesCircle,
  faListAlt, faTrash, faSitemap
} from '@fortawesome/free-solid-svg-icons';
import { IWorkflow, IVersion, IWorkflowSort, IWorkflowBase, IVersionBase } from './interfaces';
import { WorkflowService } from './services/workflow.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { ConfirmModalComponent } from '../../../shared/components/confirm-modal/confirm-modal.component';
import { Observable } from 'rxjs';
import { IOwner } from './interfaces/owner.interface';
import { NgForOfContext } from '@angular/common';
import { RunWorkflowComponent } from './components/run-workflow/run-workflow.component';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
import { IActionList } from 'src/app/authentication/interfaces/permission.interface';
import { CreateWorkflowComponent } from 'src/app/shared/components/create-workflow/create-workflow.component';

@Component({
  selector: 'app-workflow-list',
  templateUrl: './workflow-list.component.html',
  styleUrls: ['./workflow-list.component.scss']
})
export class WorkflowListComponent implements OnInit {
  faLogs: IconDefinition;
  faSearch: IconDefinition;
  faPlus: IconDefinition;
  workflows: IWorkflowBase[];
  bsModalRef: BsModalRef;
  maxSize: number;
  showActions: boolean;
  faEye: IconDefinition;
  faEdit: IconDefinition;
  faTimesCircle: IconDefinition;
  faListAlt: IconDefinition;
  faTrash: IconDefinition;

  // Sidebar variables
  @ViewChild('sideBarTemplate', { static: false }) // to be able to use dynamic scoping we set the 'NgForOfContext' as the ...
  sideBarTemplate: TemplateRef<NgForOfContext<any>>; // ... template's context (to be able to pass variables to the template dynamically)

  workflowVersions?: IVersionBase[]; // the data-structure that will hold the chosen workflow version numbers
  selectedWorkflow?: IWorkflowBase; // the data-structure that will hold the chosen workflow data (name)

  offset: number;
  limit: number;
  searchWord: string;
  selectedSortOrder: string;

  workflowNameSearch?: string;
  sort?: IWorkflowSort;
  sortOptions: IWorkflowSort[];
  ownerFilter?: IOwner;
  workflowOwners: IOwner[];
  infiniteScrollDisabled: boolean;
  actionList: IActionList;

  constructor(
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private workflowService: WorkflowService,
    private router: Router,
    private alertService: AlertService,
    private dataSharingService: DataSharingService
  ) {
    this.faSearch = faSearch;
    this.faPlus = faPlus;
    this.maxSize = 70;
    this.showActions = false;
    this.faEye = faEye;
    this.faEdit = faEdit;
    this.faTimesCircle = faTimesCircle;
    this.faListAlt = faListAlt;
    this.faTrash = faTrash;
    this.faLogs = faSitemap;
    this.offset = 0;
    this.limit = 12;
    this.infiniteScrollDisabled = false;
    this.workflowOwners = [];
    this.sortOptions = [
      { id: 'createdAt', name: 'Last Created' },
      { id: 'updatedAt', name: 'Last Updated' },
      { id: 'name', name: 'Alphabetically' }
    ];
    this.actionList = {};

    this.dataSharingService.createButton.next('workflows');

    this.workflowService.actionList.subscribe(value => {
      this.actionList = value;
    });
  }

  ngOnInit() {
    this.workflows = this.route.snapshot.data.workflows;


    if (this.actionList.readUser) {
      const ownersData = this.workflowService.getWorkflowOwners();
      ownersData.subscribe(
        (res) => {
          this.workflowOwners = res;
        });
    }
  }

  checkForRecent(createdAt: Date) {
    const now = new Date();
    return ((now.getTime() - new Date(createdAt).getTime()) / (1000 * 60 * 60 * 24)) < 1;
  }

  updateWorkflowsList(reset: boolean): void {
    this.infiniteScrollDisabled = true;

    const reqWorkflows = this.workflowService.getWorkflows(
      this.ownerFilter ? this.ownerFilter.authId : undefined,
      this.sort ? this.sort.id : undefined,
      this.workflowNameSearch,
      this.offset.toString(),
      this.limit.toString()
    );

    reqWorkflows
      .subscribe(res => {
        this.workflows = reset ? res : this.workflows.concat(res);
        this.infiniteScrollDisabled = res.length < this.limit ? true : false;
      });
  }

  getFilteredWorkflows() {
    this.offset = 0;
    this.updateWorkflowsList(true);
  }

  filterByOwner(authId?: string) {
    this.offset = 0;
    this.ownerFilter = authId ? this.workflowOwners.find(owner => owner.authId === authId) : undefined;
    this.updateWorkflowsList(true);
  }

  sortWorkflows(sortId?: string) {
    this.offset = 0;
    this.sort = sortId ? this.sortOptions.find(sortOption => sortOption.id === sortId) : undefined;
    this.updateWorkflowsList(true);
  }

  onScrollDown() {
    this.offset += this.limit;
    this.updateWorkflowsList(false);
  }

  deleteWorkflow(workflow: IWorkflow) {

    const modalOpts: ModalOptions = {
      initialState: {
        body: `Are you sure you want to permanently delete workflow ${workflow.name}`,
        initiator: 'app-workflow-list'
      },
      backdrop: 'static',
      ignoreBackdropClick: false,
      animated: true,
      keyboard: false
    };

    this.bsModalRef = this.modalService.show(ConfirmModalComponent, modalOpts);

    this.bsModalRef.content.onClose.subscribe(result => {

      if (result.initiator === 'app-workflow-list' && result.confirm) {

        this.workflowService.deleteWorkflow(workflow.key).toPromise()
          .then(() => {

            this.alertService.create({ type: 'success', body: 'the workflow is deleted successfully.', time: 3 });

            const deletedIndex = this.workflows.findIndex(ele => ele.key === workflow.key);
            this.workflows.splice(deletedIndex, 1);
            this.offset -= 1;
          });
      }
    });
  }

  openWorkflowLogs(key: string) {
    this.router.navigate([`/workflow-overview`, key], { queryParamsHandling: 'merge' });
  }

  editWorkflow(key: string) {
    this.router.navigate([`/edit-workflow`, key], { queryParamsHandling: 'merge' });
  }


  clearSelectedWorkflow(): void {
    this.selectedWorkflow = undefined;
    this.workflowVersions = undefined;
  }

  selectWorkflow(key: string): void {
    if (!this.selectedWorkflow) {
      this.selectedWorkflow = this.workflows.find(res => res.key === key);
      const versions: Observable<IVersionBase[]> = this.workflowService.getWorkflowVersions(this.selectedWorkflow.key, 0, 100, true);
      versions.subscribe((res) => {
        this.workflowVersions = res;
      });
    }
    else {
      this.clearSelectedWorkflow();
    }
  }

  runWorkflow(workflow: IWorkflow) {
    const initialState = {
      workFlowKey: workflow.key,
      inputSchema: workflow.inputSchema
    };
    this.bsModalRef = this.modalService.show(RunWorkflowComponent, { initialState, class: 'gray modal-lg' });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  public cloneWorkflow(workflow: IWorkflowBase) {
    this.workflowService.getWorkflow(workflow.key)
      .toPromise()
      .then(({ xml }) => {
        const initialState = { title: `Clone Workflow From workflow ${workflow.name}`, type: 'workflow', xml };
        this.bsModalRef = this.modalService.show(CreateWorkflowComponent, { initialState, class: 'gray modal-lg' });
        this.bsModalRef.content.closeBtnName = 'Close';
      });
  }

  public createTemplateFromWorkflow(workflow: IWorkflowBase) {
    this.workflowService.getWorkflow(workflow.key)
      .toPromise()
      .then(({ xml }) => {
        const initialState = { title: `Create Template From workflow ${workflow.name}`, type: 'template', xml };
        this.bsModalRef = this.modalService.show(CreateWorkflowComponent, { initialState, class: 'gray modal-lg' });
        this.bsModalRef.content.closeBtnName = 'Close';
      });
  }
}
