import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { ICustomTask, INewCustomTask, ICustomTaskUpdates, IFileInfo } from '../../../my-work/components/custom-task/custom-task.interface';
import { TaskService } from '../services/tasks.service';
import { IActionList } from 'src/app/authentication/interfaces/permission.interface';
import { IconDefinition, faFileDownload } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-add-custom-task',
  templateUrl: './add-custom-task.component.html',
  styleUrls: ['./add-custom-task.component.scss']
})
export class NewCustomTaskComponent implements OnInit {
  @Output() createTask = new EventEmitter<INewCustomTask>();
  @Output() updateTask = new EventEmitter<Partial<ICustomTaskUpdates>>();
  @Output() downloadCode = new EventEmitter<string>();

  faFileDownload: IconDefinition;
  newUploadedFile?: File;
  fileInfo?: IFileInfo;
  isTaskFormOpened: boolean;
  customTaskForm: FormGroup;
  uploadProgress: number;
  submitted: boolean;
  isFullscreen: boolean;
  customTaskToUpdate: ICustomTask;
  actionList: IActionList;
  supportedLanguages: string[];

  constructor(
    private formBuilder: FormBuilder,
    private tasksService: TaskService
  ) {
    this.faFileDownload = faFileDownload;
    this.isTaskFormOpened = false;
    this.uploadProgress = 0;
    this.submitted = false;
    this.isFullscreen = false;
    this.actionList = {};

    this.tasksService.actionList.subscribe(value => {
      this.actionList = value;
    });
  }


  reset(): void {
    this.submitted = false;
    this.newUploadedFile = null;
    this.fileInfo = null;
    this.isTaskFormOpened = false;
    this.customTaskToUpdate =  null;

    if (this.customTaskForm) {
      this.customTaskForm.reset({ language: this.supportedLanguages[0], hasOutput: true });
    }
  }

  ngOnInit(): void {
    this.customTaskForm = this.formBuilder.group({
      name: [null, Validators.required],
      description: [null],
      properties: [null, this.jsonValidator],
      language: ['', Validators.required],
      hasOutput: [true, Validators.required]
    });

    this.tasksService.getSupportedLanguages().subscribe(languages => {
      this.supportedLanguages = languages;
      if (this.customTaskForm) this.customTaskForm.controls.language.setValue(this.supportedLanguages[0]);
    });

    document.addEventListener('fullscreenchange', () =>
      this.isFullscreen = document.fullscreenElement ? true : false
    );
  }

  jsonValidator(control: FormControl) {

    if (control.value) {
      try {
        JSON.parse(control.value);
      }
      catch {
        return { pattern: 'Invalid Json' };
      }
    }

    return null;
  }

  public openTaskCreateForm() {
    this.isTaskFormOpened = true;
  }

  public openTaskUpdateForm(customTaskToUpdate: ICustomTask) {
    this.isTaskFormOpened = true;
    this.customTaskToUpdate = customTaskToUpdate;

    const { name, description, language, properties, hasOutput } = customTaskToUpdate;
    this.customTaskForm.setValue({
      name, description, language,
      properties: properties ? JSON.stringify(properties, null, 2) : null ,
      hasOutput: hasOutput ? true : false
    });

    this.fileInfo = { name: customTaskToUpdate.fileName, size: customTaskToUpdate.fileSize };
    this.uploadProgress = 100;
  }

  uploadCodeFile(event) {
    if (event.addedFiles && event.addedFiles.length) {
      this.newUploadedFile = event.addedFiles[0];
      const fileSize = this.newUploadedFile.size;
      this.fileInfo = { name: this.newUploadedFile.name.slice(0, 30), size: fileSize };
    }

    for (let index = 0; index < 100; index++) {
      this.increaseUploadProgress();
    }
  }

  removeCodeFile() {
    this.newUploadedFile = null;
    this.fileInfo = null;
    this.uploadProgress = 0;
  }

  increaseUploadProgress() {
    setTimeout(() => { ++this.uploadProgress; }, 500);
  }

  addCustomTask() {
    this.submitted = true;

    if (!this.customTaskForm.invalid && this.fileInfo) {
      const { name, properties, language, hasOutput, description } = this.customTaskForm.value;

      this.createTask.emit({
        name,
        properties: properties ? JSON.parse(properties) : undefined,
        fileName: this.fileInfo.name,
        fileSize: this.fileInfo.size,
        language, hasOutput, description,
        file: this.newUploadedFile
      });
    }
  }

  updateCustomTask() {
    this.submitted = true;

    if (this.customTaskToUpdate && !this.customTaskForm.invalid && this.fileInfo) {
      const { name, properties, language, hasOutput, description } = this.customTaskForm.value;

      this.updateTask.emit({
        taskId: this.customTaskToUpdate.taskId,
        properties: properties ? JSON.parse(properties) : undefined,
        fileName: this.fileInfo.name,
        fileSize: this.fileInfo.size,
        language, hasOutput, description, name,
        file: this.newUploadedFile
      });
    }
  }

  downloadTaskCode() {
    if(this.customTaskToUpdate) this.downloadCode.emit(this.customTaskToUpdate.taskId);
  }
}
